import { Link } from "react-router-dom";
import { useState } from "react";
import Search from "../Search"
import Account from "./Account";
import { ServiceData } from "../../ServiceData"
import { useNavigate } from 'react-router-dom';
import navPages from "../../Data/NavPages"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";
import "./navbar.css"

export default function NavItems() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const navigate = useNavigate();

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (

        <div className="col navbar navbar-expand-md pb-3 pb-md-0 pt-md-0 justify-content-end">
            <button
                className="navbar-toggler border-0"
                type="button"
                onClick={toggleNav}
                aria-expanded={isNavOpen ? "true" : "false"}
            >
                {isNavOpen ?

                    <FontAwesomeIcon icon={faX} />
                    :
                    <FontAwesomeIcon icon={faBars} />
                }
            </button>
            {/* <HandleToggleNav /> */}
            <div className={`navbar-backdrop ${isNavOpen ? 'show' : ''}`} onClick={toggleNav}></div>
            <div className={`collapse navbar-collapse justify-content-between ${isNavOpen ? 'show' : ''}`} id="collapsibleNavbar">
                <div className="d-flex flex-column flex-md-row align-items-center position-relative">
                    {navPages.map((page, index) => (
                        <div
                            key={index}
                            onClick={(e) => {
                                // e.preventDefault(); // Prevent default action
                                { isNavOpen ? navigate(page.pageLink) : (page.pageNameEn !== "services" && navigate(page.pageLink)); }// Navigate to the link
                            }}
                            className={`header-nav nav-link text-center ${page.addClass}`}>
                            {page.pageName}
                            <ul id="servDropdown"
                                className="flex-wrap position-absolute bg-light-gray border border-white rounded p-2 col-12 list-unstyled shadow">
                                {ServiceData.map((data, index) => (
                                    <li key={index} className="col-12 col-md-4" >
                                        <Link
                                            // onClick={() => navigate(data.btnLink)}
                                            to={data.btnLink}
                                            className={`dropdown-item px-1 py-2 ${data.status}`}>
                                            {data.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}

                </div>
                {/* <Search /> */}
                <Account />
            </div>
        </div>
    );
}

