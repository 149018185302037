import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { allBooksCategories } from "../components/Data/CategoriesData"
import ReCaptchaForForms from '../helpers/ReCaptchaForForms';
import { apiFetch } from "../helpers/apiFetch"


export default function RequestAddBook() {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [dataUpdate, setDataUpdate] = useState(false);
    const [loading, setLoading] = useState(false);

    const thisYear = new Date().getFullYear();

    async function onSubmit(data) {
        setLoading(true);
        console.log("Form data: ", data); // Debug: Check form data
        // if (data.publishDate) {
        //     data.publishDate.toString();
        // }
        // if (data.numberOfPages) {
        //     parseInt(data.numberOfPages);
        // }

        try {
            const response = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/mailer/add-book`, {
                method: "POST",
                body: JSON.stringify(data)
            });

            // Redirect to another page after successful submission
            if (response.isSuccess) {
                setDataUpdate(true)
                setTimeout(() => {
                    window.location.reload()
                    // navigate('/');
                }, 3000);

            } else {
                // console.log(response, response.message, response.status);
                setErrorMessage("حدث خطأ ما، حاول مرة أخرى", response.message);
            }
        } catch (error) {
            console.error("Error during form submission: ", error); // Debug: Check error
            setErrorMessage("حدث خطأ ما، تواصل مع الدعم");
        } finally {
            setLoading(false);
            // navigate('/');

        }
    }



    const handleSelect = (e) => {
        const selectedValue = e.target.value;
        const category = allBooksCategories.find(cat => cat.en === selectedValue);
        // Use category.en for internal logic
    };

    return (

        <form id='RequestAddBook-form' className='d-flex flex-column shadow mx-auto my-5 gap-2 text-start bg-white p-4 rounded' onSubmit={handleSubmit(onSubmit)}>
            {errorMessage && <p className='errors-msg bg-warning p-1 rounded' id='error-message'>{errorMessage}</p>}

            <input
                className={`form-control border`}
                type="text"
                placeholder="عنوان الكتاب"
                {...register("title", { required: true, minLength: 4 })} />
            {errors.title && <p className='errors-msg'> عنوان الكتاب مطلوب أكبر من ٣ أحرف</p>}

            <input
                className={`form-control border`}
                type="text"
                placeholder="اسم المؤلف"
                {...register("author", { required: true, minLength: 10 })} />
            {errors.author && <p className='errors-msg'> اسم المؤلف مطلوب أكبر من ١٠ أحرف</p>}


            <div className="input-group input-group-sm">
                <span className="input-group-text">التصنيف</span>
                <select className="form-select" aria-label="request-books-category"
                    {...register("category", { required: true })}
                    onChange={() => handleSelect}>
                    <option value=""></option>
                    {allBooksCategories.map((category, index) =>
                        <option key={index} value={category.ar}>{category.ar}</option>
                    )}
                </select>
                {errors.category && <p className='errors-msg'>مطلوب</p>}
            </div>


            <input
                className={`form-control border`}
                type="number"
                placeholder="تاريخ النشر"
                {...register("publishDate", { required: true, pattern: /^\d{4}$/, min: 1980, max: { thisYear } })} />
            {errors.publishDate && <p className='errors-msg'> الارقام فقط للسنة - تاريخ النشر أكبر من ١٩٨٠م</p>}

            <input
                className={`form-control border`}
                type="number"
                min="20"
                max="2000"
                placeholder="عدد الصفحات"
                onChange={(e) => setValue("numberOfPages", parseInt(e.target.value))}
            // {...register("numberOfPages", { min: 20, max: 2000 })}
            />
            {errors.numberOfPages && <p className='errors-msg'> عدد الصفحات أكبر من ٢٠ صفحة</p>}


            {/* <div className="align-self-end">
                <ReCaptchaForForms />
            </div> */}
            <button className="btn btn-light-blue text-white"
                type='submit'>أرسل الطلب</button>
            {errorMessage &&
                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    {errorMessage}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>}

            {dataUpdate &&
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    تم إرسال طلبك بنجاح .. شكرا لك
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>}
        </form>

    )
}

