import { useNavigate } from 'react-router-dom';
import { apiFetchAdmin } from '../helpers/apiFetch';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { allStatusListItems } from './Data/CategoriesData';
import { editOrderOffer } from '../helpers/apiAxios';
import { handleFileChange } from "../helpers/HandleFunctions/HandleFileUpload"
import SuccessErrorMsg from "../components/SuccessErrorMsg"
import { handlePDFClick } from "../helpers/HandleFunctions/HandlePDFOpen"

export default function OrderDetials({ orderId, numberOfForms, language, orderDescription, subServices, orderStatus, offerStatus, serviceName, offerPrice, offerNote, offerFile, customerEmail, customerName, customerPhone, adminRole }) {
    const [loading, setLoading] = useState(false);
    // const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [dataUpdate, setDataUpdate] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [profile, setProfile] = useState(null);
    const [superAdmin, setSuperAdmin] = useState(false)
    const [allServiceData, setAllServiceData] = useState(null);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: {
            price: offerPrice,
            notes: offerNote,
            file: offerFile,
        }
    });

    async function onOfferChange(formData) {
        setLoading(true);
        console.log(formData);

        try {
            const response = await editOrderOffer(orderId, formData);

            if (response.data.isSuccess) {
                setDataUpdate(true);
                setTimeout(() => {
                    window.location.reload();
                }, 3000);

            } else {
                setErrorMessage("حدث خطأ ما، حاول مرة أخرى");
            }
        } catch (error) {
            console.error("Error during form submission: ", error);
            setErrorMessage("حدث خطأ ما، تواصل مع الدعم");
        } finally {
            setLoading(false);
        }
    }


    async function onSubmit(formData) {
        setLoading(true);
        console.log(formData);

        try {
            const response = await editOrderOffer(orderId, formData);

            if (response.data.isSuccess) {
                setDataUpdate(true);
                setTimeout(() => {
                    window.location.reload();
                }, 3000);

            } else {
                setErrorMessage("حدث خطأ ما، حاول مرة أخرى");
            }
        } catch (error) {
            console.error("Error during form submission: ", error);
            setErrorMessage("حدث خطأ ما، تواصل مع الدعم");
        } finally {
            setLoading(false);
        }
    }

    // navigate('/setting/services');


    return (
        <div id={`order-details-${orderId}`}
            className="modal fade"
            tabIndex="-1"
            aria-hidden="true"
            aria-labelledby="orderDetailsLabel"
            data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="orderDetailsLabel">تفاصيل العرض</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <table className="table  table-striped">
                            <tbody>
                                <tr>
                                    <td>اسم العميل</td>
                                    <td>{customerName}</td>
                                </tr>
                                <tr>
                                    <td>رقم التواصل</td>
                                    <td>{customerPhone}</td>
                                </tr>
                                <tr>
                                    <td>البريد الالكتروني</td>
                                    <td>{customerEmail}</td>
                                </tr>
                            </tbody>
                        </table>

                        <h6 className="mt-3">{serviceName}</h6>

                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>عدد النماذج</td>
                                    <td>{numberOfForms}</td>
                                </tr>
                                {language === "arabic" && <tr>
                                    <td>اللغة</td>
                                    <td>عربي</td>
                                </tr>}
                                {language === "english" && <tr>
                                    <td>اللغة</td>
                                    <td>إنجليزي</td>
                                </tr>}
                                {language === "arabic + english" && <tr>
                                    <td>اللغة</td>
                                    <td>عربي + إنجليزي</td>
                                </tr>}
                                {subServices &&
                                    <tr>
                                        <td>الخدمات المطلوبة</td>
                                        <td>{subServices}</td>
                                    </tr>
                                }
                                <tr>
                                    <td>حالة الطلب</td>
                                    <td>{orderStatus}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                            <h6 className="mt-4">تفاصيل</h6>
                            <p>{orderDescription}</p>
                        </div>

                        {/* {profile?.data?.role === "superAdmin" & offerPrice > 1 ? */}
                        {adminRole === "superAdmin" & offerPrice > 1 ?
                            <form className='d-flex flex-wrap text-start'
                                onSubmit={handleSubmit(onOfferChange)}>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>السعر المرسل</td>
                                            <td>{offerPrice} ر.س</td>
                                        </tr>
                                        <tr>
                                            <td>العرض</td>
                                            <td>
                                                <button className="btn-masar-sm btn-med-gray m-1" onClick={handlePDFClick} value={offerFile}>PDF</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>الملاحظات</td>
                                            <td>{offerNote}</td>
                                        </tr>
                                        <tr>
                                            <td>حالة الطلب</td>
                                            <td>
                                                <select className='form-select form-select-sm'
                                                    // onChange={e => setOfferDataStatus(e.target.value)
                                                    onChange={e => setValue("status", e.target.value)}
                                                // {...register("status")}
                                                >
                                                    <option defaultValue={offerStatus}>{offerStatus}</option>
                                                    {allStatusListItems
                                                        .filter(oneStatus => oneStatus.en !== offerStatus)
                                                        .map((oneStatus, index) => (
                                                            <option key={index} value={oneStatus.en}>
                                                                {oneStatus.ar}
                                                            </option>
                                                        ))}
                                                </select>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                {/* <input
                                    placeholder='ضع السعر غير شامل الضريبة'
                                    className='d-none'
                                    type="number" step="any" defaultValue={offerPrice}
                                    {...register("price")} /> */}

                                {/* <div className="input-group input-group-sm">
                                    <span className="input-group-text" >العرض</span>
                                    <input className="form-control form-control-sm" type="file" accept=".pdf"
                                        required
                                        onChange={(e) => handleFileChange(e, setValue)}
                                    // {...register("file", { required: true })}
                                    />
                                    {errors.file && <p className='errors-msg'>أضف ملف العرض</p>}

                                </div> */}

                                {/* <input
                                    className='form-control form-control-sm '
                                    // className='d-none'
                                    type="text"
                                    defaultValue={offerNote}
                                    {...register("notes")} /> */}




                                <button className="btn-masar btn-dark-orange ms-auto" type="submit">
                                    {loading &&
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"></span>
                                    }

                                    حفظ</button>
                                <SuccessErrorMsg
                                    errorMessage={errorMessage}
                                    dataUpdate={dataUpdate}
                                    successMessage={"تم حفظ العرض .. "}
                                />

                            </form> :
                            <div className="alert alert-info text-center" role="alert">
                                لا يوجد عرض مقدم</div>
                        }

                        {adminRole !== "superAdmin" &&
                            <form className='d-flex flex-wrap text-start pe-5 ps-3 pb-4 gap-1'
                                onSubmit={handleSubmit(onSubmit)}>

                                <div className="input-group input-group-sm">
                                    <span className="input-group-text" >السعر</span>
                                    <input
                                        placeholder='ضع السعر غير شامل الضريبة'
                                        className='form-control form-control-sm text-start'
                                        type="number" step="any"
                                        {...register("price", { required: true })} />
                                    {errors.email && <p className='errors-msg'>√</p>}
                                </div>

                                <div className="input-group input-group-sm">
                                    <span className="input-group-text" >العرض</span>
                                    <input className="form-control form-control-sm" type="file" accept=".pdf"
                                        required
                                        onChange={(e) => handleFileChange(e, setValue, setError)}
                                    // {...register("file", { required: true })}
                                    />
                                    {errors.file && <p className='errors-msg'>أضف ملف العرض</p>}

                                </div>

                                <div className="input-group input-group-sm w-100 ">
                                    <span className="input-group-text" >ملاحظات</span>
                                    <input
                                        className='form-control form-control-sm '
                                        type="text"
                                        {...register("notes")} />
                                </div>

                                <div className="d-none">
                                    <span className="input-group-text" >الحالة</span>
                                    <input className='form-control form-control-sm' disabled
                                        {...register("status")}
                                        value="Pending" />
                                </div>


                                <button
                                    className='btn-masar btn-dark-orange text-white col-2 ms-auto my-2 float-end'
                                    type="submit">
                                    {loading &&
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"></span>
                                    }
                                    تحديث
                                </button>
                                <SuccessErrorMsg
                                    errorMessage={errorMessage}
                                    dataUpdate={dataUpdate}
                                    successMessage={"تم إرسال العرض .."}
                                />
                            </form>}
                    </div>
                </div>
            </div>
        </div>
    );
}
