import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpenReader } from "@fortawesome/free-solid-svg-icons";

function CartBooksSummary({ totalBooks, totalBooksPrice }) {
    const navigate = useNavigate();
    return (
        <div className="col-11 col-md-3 bg-white rounded text-center my-3 mx-auto me-md-4 p-2 d-flex flex-column justify-content-between">
            <div className="mb-3">
                <h6 className="mb-2">سلة الكتب</h6>
                {totalBooks ?
                    <>
                        <p>عدد الكتب: <span className="c-darkOrange fs-6">{totalBooks}</span></p>
                        <p>المبلغ الإجمالي: <br /><span className="c-darkBlue fs-6">{totalBooksPrice && totalBooksPrice + "ريال"}</span></p>
                    </> :
                    <p className="text-warning">السلة فارغة</p>
                }
            </div>
            <div className="d-flex flex-column">
                <button className={`btn-masar text-white m-1 ${!totalBooks ? "btn-disabled" : "btn-dark-orange"}`}
                    disabled={!totalBooks}
                    onClick={() => {
                        sessionStorage.setItem('paymentType', 'books');
                        navigate("/payment")
                    }}>إتمام الشراء</button>
                <button className="btn-masar btn-light-blue text-white m-1"
                    // disabled={!totalBooks}
                    onClick={() => navigate("/masarLibrary")}><FontAwesomeIcon icon={faBookOpenReader} /> المكتبة</button>
            </div>
        </div>
    )
};

function CartOrdersSummary({ setValue, totalOrders, totalOrdersPrice }) {
    const navigate = useNavigate();
    return (
        <div className="col-11 col-md-3 text-center my-3 mx-auto me-md-4 px-2 d-flex flex-column justify-content-between">
            <div className="mb-3">
                <h6 className="mb-2">سلة الطلبات</h6>
                {totalOrders ?
                    <>
                        <p>المبلغ الإجمالي: <br /><span className="c-darkBlue fs-6">{totalOrdersPrice && totalOrdersPrice + "ريال"}</span></p>
                    </> :
                    <p className="text-warning">السلة فارغة</p>
                }
            </div>
            <div className="d-flex flex-column">
                <button className={`btn-masar text-white m-1 ${!totalOrders ? "btn-disabled" : "btn-dark-orange"}`}
                    disabled={!totalOrders}
                    onClick={() => {
                        sessionStorage.setItem('paymentType', 'services');
                        navigate("/payment")
                    }}>إتمام الشراء</button>
            </div>
        </div>
    )
};

export { CartBooksSummary, CartOrdersSummary };