const navPages = [{
    pageName: "خدماتنا",
    pageLink: "/services",
    pageNameEn: "services",
    addClass: "servHBtn",
},
{
    pageName: "مكتبة مسار",
    pageLink: "/masarLibrary",
    pageNameEn: "library",
    addClass: "",
}, {
    pageName: "من أعمالنا",
    pageLink: "/profile",
    pageNameEn: "profile",
    addClass: "",
}, {
    pageName: "عن مسار",
    pageLink: "/aboutUs",
    pageNameEn: "about",
    addClass: "",
},];

export default navPages;