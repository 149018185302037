import React, { useEffect, useState } from 'react';


function OTPPage() {
    const [otp, setOTP] = useState('');
    const [userOTP, setUserOTP] = useState('');
    const [registrationSuccess, setRegistrationSuccess] = useState(false);


    // // Function to generate a random 6-digit OTP
    // const generateOTP = () => {
    //     const digits = '0123456789';
    //     let OTP = '';
    //     for (let i = 0; i < 6; i++) {
    //         OTP += digits[Math.floor(Math.random() * 10)];
    //     }
    //     setOTP(OTP);
    //     console.log(OTP);
    // };

    // // Function to handle OTP verification
    // const verifyOTP = () => {
    //     if (otp === userOTP) {
    //         setRegistrationSuccess(true);
    //         // Perform registration logic here (e.g., send registration data to server)
    //     } else {
    //         alert('رمز التأكيد غير صحيح فضلا أعد المحاولة');
    //     }
    // };

    const goBack = () => {
        window.history.back();
    };

    return (
        <div className='dashboard d-flex flex-row col-12'>
            <div className='dashboard-form container col-12 col-md-6 mx-auto my-auto px-4 text-center'>
                {registrationSuccess ? (
                    <h4 className='c-darkOrange'>شكرا لقم تم التسجيل</h4>
                ) : (
                    <>
                        <h5 className='c-darkOrange'>رمز التأكيد</h5>
                        <p>فضلا ادخل رمز التأكيد المرسل إليك</p>

                        <form className='d-flex flex-row flex-wrap col-10 mx-auto my-5 gap-2 text-start'>
                            <input className='form-control'
                                placeholder='XXXXXX'
                                type="text"
                                id="otpInput"
                                value={userOTP}
                                onChange={(e) => setUserOTP(e.target.value)}
                            />
                            <input className='btn btn-light-blue text-white col' type="submit" value="تأكيد" />
                            <input className='text-end btn btn-light-blue text-white' type="button" value="عودة" onClick={goBack} />
                        </form>
                        {/* <button onClick={generateOTP}>Generate OTP</button> */}
                    </>
                )}
            </div>
        </div>
    );
}

export default OTPPage;
