import { ToastContainer, toast } from 'react-toastify';

export default function SuccessErrorMsg({ errorMessage, dataUpdate, successMessage }) {

    // toast.info(`${"Note"}: ${errorMessage ? errorMessage : successMessage}`);


    // <ToastContainer />

    return (

        <>
            {errorMessage &&
                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    {errorMessage}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>

                </div>
            }
            {dataUpdate &&
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {successMessage}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>}

        </>
    )
}

