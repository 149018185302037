import { AdminSideBar } from "../components/UserSideBar";
import React, { useState, useEffect } from 'react';
import { apiFetchAdmin } from '../helpers/apiFetch';
import moment from 'moment';
import 'moment/locale/ar'
import Popup from 'reactjs-popup';
import { ModiratorData } from "../components/ModiratorData";
import { Outlet } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


export default function AdminSetting() {
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
    const [selectedModirator, setSelectedModirator] = useState("");
    const navigate = useNavigate();


    useEffect(() => {
        if (!Cookies.get('adminToken')) {
            // if (!localStorage.getItem("adminToken")) {
            navigate("/adminLogin")
        }
    }, [navigate])


    useEffect(() => {
        async function fetchProfile() {
            try {
                const data = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/users/my-profile`);
                setProfile(data);

            } catch (err) {
                setError("هناك مشكلة تواصل مع الدعم");
            }
        }

        fetchProfile();
    }, []);

    return (
        <div className='container dashboard col-12 position-relative d-flex p-0'>
            <div className='col-1 col-md-2 text-start bg-light-blue'>
                <AdminSideBar superAdminRole={profile?.data?.role} />
            </div>
            <Outlet />
        </div>
    )
}
