import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";

export default function PrivecyPolicy() {
    return (
        <div className="container">
            <h4 className="text-center c-darkBlue">سياسة الخصوصية لمنصة مسار</h4>

            <h6 className="c-darkOrange"><strong>أ&ndash; مقدمة</strong></h6>
            <ol>
                <li>
                    إننا نهتم بشكل كبير بخصوصية زوار موقعنا، ونتعهد بحمايتها. تشرح هذه السياسة كيفية التصرف في معلوماتك الشخصية.
                </li>
                <li>
                    بموافقتك على استخدامنا لملفات تعريف الارتباط وفقًا لبنود هذه السياسة عند زيارتك لموقعنا الإلكتروني لأول مرة فإنك تسمح لنا باستخدام ملفات تعريف الارتباط في كل مرة تزور فيها موقعنا.
                </li>
            </ol>
            <h6 className="c-darkOrange"><strong>ب&ndash; جمع المعلومات الشخصية</strong></h6>

            <ol>
                <li>
                    قد يتم جمع وتخزين واستخدام المعلومات الشخصية التالية: معلومات حول جهاز الكمبيوتر الخاص بك بما في ذلك عنوان بروتوكول الإنترنت الخاص بك، والموقع الجغرافي، ونوع المتصفح والإصدار، ونظام التشغيل.
                </li>
                <li>
                    معلومات حول زياراتك لهذا الموقع واستخدامه بما في ذلك مصدر الإحالة، ومدة الزيارة، وما تشاهده على الصفحة، ومسارات التصفح في الموقع.
                </li>
                <li>
                    المعلومات التي تدخلها عند التسجيل في موقعنا الإلكتروني، مثل عنوان بريدك الإلكتروني، ورقم الجوال.
                </li>
                <li>
                    المعلومات التي تدخلها عند إنشاء ملف تعريف على موقعنا &ndash; على سبيل المثال، اسمك، صور ملفك الشخصي، الجنس، تاريخ الميلاد، الحالة الاجتماعية، الاهتمامات والهوايات، الحالة التعليمية، وتفاصيل الوظيفة.
                </li>
                <li>
                    المعلومات، مثل اسمك وعنوان بريدك الإلكتروني، التي تدخلها من أجل إعداد اشتراكات في رسائل البريد الإلكتروني و / أو الرسائل الإخبارية لدينا.
                </li>
                <li>
                    المعلومات التي تدخلها أثناء استخدام الخدمات على موقعنا.
                </li>
                <li>
                    المعلومات التي يتم إنشاؤها أثناء استخدام موقعنا الإلكتروني، بما في ذلك مواعيد وتكرار استخدامك للموقع والظروف التي تستخدم فيها الموقع.
                </li>
                <li>
                    المعلومات المتعلقة بأي شيء تشتريه، أو الخدمات التي تستخدمها، أو المعاملات التي تجريها من خلال موقعنا الإلكتروني، والتي تشمل اسمك وعنوانك ورقم هاتفك وعنوان بريدك الإلكتروني وتفاصيل بطاقة الائتمان.
                </li>
                <li>
                    المعلومات التي تنشرها على موقعنا الإلكتروني بغرض مشاركتها على الإنترنت، والتي تشمل اسم المستخدم الخاص بك وصور ملفك الشخصي ومحتوى مشاركاتك.
                </li>
                <li>
                    المعلومات الواردة في أي مراسلات ترسلها إلينا عبر البريد الإلكتروني أو عبر موقعنا الإلكتروني، بما في ذلك المحتوى المشترك والبيانات الوصفية.
                </li>
                <li>
                    أي معلومات شخصية أخرى ترسلها إلينا.
                </li>
            </ol>
            <p className="w-75 mx-auto bg-white p-1 rounded">
                قبل أن تفصح لنا عن المعلومات الشخصية الخاصة بشخص آخر، ينبغي عليك الحصول على موافقة هذا الشخص على كل من الكشف عن هذه المعلومات الشخصية ومعالجتها وفقًا لهذه السياسة.
            </p>
            <h6 className="c-darkOrange"><strong>ج&ndash; استخدام معلوماتك الشخصية</strong></h6>
            <p className="fw-bold">
                تستخدم المعلومات الشخصية المقدمة إلينا عبر موقعنا الإلكتروني في الأغراض الموضحة في هذه السياسة أو على الصفحات ذات الصلة من الموقع. قد نستخدم معلوماتك الشخصية في الأغراض التالية:
            </p>
            <ol>
                <li>
                    إدارة موقعنا وأعمالنا على شبكة الإنترنت.
                </li>
                <li>
                    تخصيص موقعنا على شبكة الإنترنت ليكون أكثر ملاءمة بالنسبة لك.
                </li>
                <li>
                    تمكينك من استخدام الخدمات المتاحة على موقعنا.
                </li>
                <li>
                    إرسال البضائع التي تشتريها من الإنترنت إليك.
                </li>
                <li>
                    توفير الخدمات المشتراة من خلال موقعنا على شبكة الإنترنت.
                </li>
                <li>
                    إرسال البيانات والفواتير وإشعارات الدفع لك، وتحصيل المدفوعات منك.
                </li>
                <li>
                    إرسال رسائل تجارية غير تسويقية إليك.
                </li>
                <li>
                    إرسال إشعارات عبر البريد الإلكتروني التي قمت بطلب إرسالها على وجه التحديد إليك.
                </li>
                <li>
                    إرسال نشرة البريد الإلكتروني إليك، إذا كنت قد طلبت ذلك (يمكنك إبلاغنا في أي وقت إذا لم تعد تحتاج إلى النشرة الإخبارية).
                </li>
                <li>
                    إرسال المراسلات التسويقية المتعلقة بأعمالنا أو أعمال الجهات الخارجية التي تم اختيارها بعناية والتي نعتقد أنها قد تهمك، عن طريق البريد أو، إذا كنت قد وافقت على ذلك بالتحديد، عن طريق البريد الإلكتروني أو أي تقنية مشابهة (يمكنك إبلاغنا في أي وقت إذا لم تعد تحتاج إلى هذه المراسلات التسويقية).
                </li>
                <li>
                    تزويد الأطراف الثالثة بمعلومات إحصائية عن مستخدمينا (غير أن هذه الأطراف الثالثة لن تتمكن من تحديد المستخدمين لتلك المعلومات بحد ذاتهم).
                </li>
                <li>
                    التعامل مع الاستفسارات والشكاوى التي تقدمها أنت أو تقدم لنا بشأنك على موقعنا.
                </li>
                <li>
                    حماية موقعنا من الاحتيال والحفاظ على أمنه.
                </li>
                <li>
                    التحقق من الامتثال للشروط والأحكام التي تحكم استخدام موقعنا الإلكتروني (بما في ذلك مراقبة الرسائل الخاصة المرسلة عبر خدمة الرسائل الخاصة بموقعنا).
                </li>
                <li>
                    استخدامات أخرى:
                    <ul>
                        <li>
                            إذا قمت بتقديم معلومات شخصية للنشر على موقعنا، فسنقوم بنشر هذه المعلومات واستخدامها بشكل آخر وفقًا للترخيص الذي تمنحه لنا.
                        </li>
                        <li>
                            يمكن استخدام إعدادات الخصوصية الخاصة بك للحد من نشر معلوماتك على موقعنا الإلكتروني ويمكن تعديلها باستخدام عناصر التحكم في الخصوصية على الموقع.
                        </li>
                        <li>
                            لن نزود أي طرف ثالث، دون موافقتك الصريحة، بمعلوماتك الشخصية لأغراض التسويق المباشر لأي طرف آخر.
                        </li>
                    </ul>
                </li>
            </ol>
            <h6 className="c-darkOrange"><strong>د&ndash; الكشف عن المعلومات الشخصية</strong></h6>
            <p className="fw-bold">
                يمكن أن نقوم بالكشف عن معلوماتك الشخصية لأي من موظفينا أو مسؤولينا أو شركات التأمين أو المستشارين المحترفين أو الوكلاء أو الموردين أو المقاولين من الباطن التابعين لنا القدر الضروري بشكل معقول للأغراض المنصوص عليها في هذه السياسة.
                <br />
                قد نقوم بالإفصاح عن معلوماتك الشخصية لأي عضو في مجموعة شركاتنا (وهذا يعني الشركات التابعة لنا، والشركة القابضة الأصلية وجميع فروعها) بالقدر الضروري بشكل معقول للأغراض المنصوص عليها في هذه السياسة.
                قد نكشف عن معلوماتك الشخصية:
            </p>
            <ol>
                <li>
                    القدر الذي يطلب منا القيام به بموجب القانون.
                </li>
                <li>
                    فيما يتعلق بأي إجراءات قانونية جارية أو محتملة.
                </li>
                <li>
                    من أجل إنشاء أو ممارسة أو الدفاع عن حقوقنا القانونية (بما في ذلك توفير معلومات للآخرين لأغراض منع الاحتيال والحد من مخاطر الائتمان).
                </li>
                <li>
                    إلى المشتري (أو المشتري المحتمل) لأي عمل أو أصل نبيعه (أو ننوي ذلك).
                </li>
                <li>
                    أي شخص نعتقد على نحو معقول أنه قد يتقدم بطلب إلى محكمة أو سلطة مختصة أخرى بطلب الإفصاح عن تلك المعلومات الشخصية، وفقًا لتقديرنا المعقول، ويكون من المحتمل بشكل معقول أن تأمر هذه المحكمة أو السلطة بالكشف عن تلك المعلومات الشخصية. باستثناء ما هو منصوص عليه في هذه السياسة، فإننا لن نقدم معلوماتك الشخصية إلى أطراف ثالثة.
                </li>
            </ol>
            <h6 className="c-darkOrange"><strong>هـ&ndash; النقل الدولي للبيانات</strong></h6>
            <ol>
                <li>
                    قد يتم تخزين المعلومات التي نجمعها أو معالجتها أو نقلها بين أي من البلدان التي نعمل فيها لتمكيننا من استخدام المعلومات وفقًا لهذه السياسة.
                </li>
                <li>
                    قد يتم نقل المعلومات التي نجمعها إلى البلدان التالية والتي لا تخضع لقوانين حماية البيانات المكافئة لتلك المعمول بها في المنطقة الاقتصادية الأوروبية: وهذه الدول هي الولايات المتحدة الأمريكية، روسيا، اليابان، الصين، والهند.
                </li>
                <li>
                    لا نستطيع منع استخدام الآخرين أو إساءة استخدامهم للمعلومات الشخصية التي تنشرها على موقعنا الإلكتروني أو ترسلها للنشر على موقعنا الإلكتروني والمتاحة عبر الإنترنت في جميع أنحاء العالم.
                </li>
                <li>
                    أنت توافق صراحة على عمليات نقل المعلومات الشخصية الموضحة في هذا القسم &ldquo;هـ&rdquo;.
                </li>
            </ol>

            <h6 className="c-darkOrange"><strong>و&ndash; الاحتفاظ بالمعلومات الشخصية</strong></h6>
            <ol>
                <li>
                    &nbsp;يحدد هذا القسم &ldquo;و&rdquo; السياسات والإجراءات التي نتبعها للاحتفاظ بالبيانات، والتي صممت للمساعدة في ضمان امتثالنا لالتزاماتنا القانونية المتعلقة بالاحتفاظ بالمعلومات الشخصية وحذفها.
                </li>
                <li>
                    لا يجوز الاحتفاظ بالمعلومات الشخصية التي نقوم بمعالجتها لأي غرض أو أغراض لفترة أطول مما هو ضروري لهذا الغرض أو لتلك الأغراض.
                </li>
                <li>
                    بصرف النظر عن الأحكام الأخرى الواردة في هذا القسم و، فإننا نحتفظ بالوثائق (بما في ذلك المستندات الإلكترونية) التي تحتوي على بيانات شخصية:
                    <ul>
                        <li>
                            بالقدر المطلوب بموجب القانون.
                        </li>
                        <li>
                            إذا كنا نعتقد أن هذه المستندات قد تكون ذات صلة بأي إجراءات قانونية جارية أو محتملة.
                        </li>
                        <li>
                            من أجل بدء أو ممارسة أو الدفاع عن حقوقنا القانونية (بما في ذلك توفير المعلومات للآخرين لأغراض منع الاحتيال والحد من مخاطر الائتمان).
                        </li>
                    </ul>
                </li>
            </ol>

            <h6 className="c-darkOrange"><strong>ز&ndash; أمن معلوماتك الشخصية</strong></h6>

            <ol>
                <li>
                    أمن معلوماتك الشخصية: نتعهد باتخاذ الاحتياطات التقنية والتنظيمية المعقولة لمنع فقدان معلوماتك الشخصية أو إساءة استخدامها أو تغييرها.
                </li>
                <li>
                    نتعهد بتخزين جميع المعلومات الشخصية التي تقدمها على خوادمنا الآمنة (المحمية بكلمة مرور وجدار الحماية).
                </li>
                <li>
                    جميع المعاملات المالية الإلكترونية التي يتم القيام بها من خلال موقعنا الإلكتروني محمية بتقنية التشفير.
                </li>
                <li>
                    تقرّ بموافقتك على هذه السياسة بمعرفتك بأن نقل المعلومات عبر الإنترنت غير آمن بطبيعته، ولا يمكننا ضمان أمن البيانات المرسلة عبر الإنترنت.
                </li>
                <li>
                    أنت مسؤول عن الحفاظ على سرية كلمة المرور التي تستخدمها للوصول إلى موقعنا. لن نطلب منك كلمة المرور الخاصة بك (إلا عند تسجيل الدخول إلى موقعنا).
                </li>
            </ol>

            <h6 className="c-darkOrange"><strong>ح&ndash; التعديلات</strong></h6>
            <p>قد نقوم بتحديث هذه السياسة من وقت لآخر عن طريق نشر نسخة جديدة على موقعنا. يجب عليك مراجعة هذه الصفحة من حين لآخر للتأكد من فهمك لأي تغييرات تطرأ على هذه السياسة، وقد نخطرك بالتغييرات التي تطرأ على هذه السياسة عن طريق البريد الإلكتروني أو من خلال نظام المراسلة الخاص على موقعنا.</p>

            <h6 className="c-darkOrange"><strong>ط&ndash; حقوقك</strong></h6>
            <ol>
                <li>
                    قد تطلب منا تزويدك بأي معلومات شخصية نحتفظ بها عنك، يخضع توفير هذه المعلومات لما يلي:
                    <ul>
                        <li>
                            دفع الرسوم (تحدد الرسوم من قبل الإدارة وترسل لكم في فاتورة رسمية).
                        </li>
                        <li>
                            وتقديم إثبات الهوية المناسب (طبقا لمحل الإقامة والدولة والسياسات المتبعة وقتها) .
                        </li>
                    </ul>
                </li>
                <li>
                    قد نمتنع عن تزويدك بالمعلومات الشخصية التي تطلبها بالقدر الذي يسمح به القانون.
                </li>
                <li>
                    قد تطلب منا في أي وقت عدم معالجة معلوماتك الشخصية لأغراض التسويق.
                </li>
                <li>
                    من الناحية العملية، إما أنك ستوافق بشكل صريح مسبقًا على استخدامنا لمعلوماتك الشخصية لأغراض التسويق، أو سنمنحك فرصة لإلغاء الاشتراك في استخدام معلوماتك الشخصية لأغراض التسويق.
                </li>
            </ol>
            <h6 className="c-darkOrange"><strong>ي&ndash; مواقع الطرف الثالث</strong></h6>
            <p>
                قد يشتمل موقعنا على روابط تشعبية وتفاصيل عن مواقع خاصة بأطراف ثالثة، علمًا بأنه ليس لدينا أي سيطرة على سياسات الخصوصية وممارسات الأطراف الثالثة كما أننا لسنا مسؤولين عنها.
            </p>

            <h6 className="c-darkOrange"><strong>ك&ndash; تحديث المعلومات</strong></h6>
            <p>
                يرجى إعلامنا إذا كانت المعلومات الشخصية التي نحتفظ بها عنك تحتاج إلى تصحيح أو تحديث.
            </p>

            <h6 className="c-darkOrange"><strong>ل&ndash; ملفات تعريف الارتباط</strong></h6>

            يستخدم موقعنا ملفات تعريف الارتباط. ملف تعريف الارتباط هو ملف يحتوي على معرف (سلسلة من الحروف والأرقام) يتم إرساله بواسطة خادم الإنترنت إلى متصفح الإنترنت ويتم تخزينه بواسطة المتصفح، ثم يتم إرسال المعرف مرة أخرى إلى الخادم في كل مرة يطلب فيها المتصفح صفحة من الخادم. قد تكون ملفات تعريف الارتباط إما ملفات تعريف ارتباط &ldquo;دائمة&rdquo; أو ملفات تعريف ارتباط خاصة بـ &ldquo;جلسة&rdquo;: يخزن ملف تعريف الارتباط الدائم بواسطة متصفح الإنترنت ويظل صالحًا حتى تاريخ انتهاء الصلاحية المحدد له، ما لم يتم حذفه من قبل المستخدم قبل تاريخ انتهاء الصلاحية؛ من ناحية أخرى، تنتهي صلاحية ملف تعريف ارتباط الجلسة بنهاية جلسة المستخدم، أي بإغلاق متصفح الإنترنت. لا تحتوي ملفات تعريف الارتباط عادةً على أي معلومات تعرّف عن المستخدم شخصيًا، ولكن قد يتم ربط المعلومات الشخصية التي نخزنها عنك بالمعلومات المخزنة في ملفات تعريف الارتباط التي يتم الحصول عليها.

            <ol>
                <li>
                    فيما يلي أسماء ملفات تعريف الارتباط التي نستخدمها على موقعنا الإلكتروني والأغراض التي تستخدم من أجلها :
                    <ul>
                        <li>
                            نستخدم تحليلات جوجل و Adwords على موقعنا للتعرف على جهاز الكمبيوتر عندما يقوم المستخدم بـ ( زيارة الموقع الإلكتروني / تتبع المستخدمين أثناء تصفحهم للموقع / تمكين استخدام عربة التسوق على الموقع / تحسين قابلية استخدام الموقع / تحليل استخدام الموقع / إدارة الموقع / منع الاحتيال وتحسين أمن الموقع / تخصيص الموقع لكل مستخدم / الإعلانات المستهدفة التي قد تكون ذات أهمية خاصة لمستخدمين محددين).
                        </li>
                    </ul>
                </li>
                <li>
                    تسمح لك معظم المتصفحات برفض قبول ملفات تعريف الارتباط &mdash; على سبيل المثال:
                    <ul>
                        <li>
                            في متصفح إنترنت إكسبلورر Internet Explorer (الإصدار 10)، يمكنك حظر ملفات تعريف الارتباط باستخدام إعدادات تجاوز التعامل مع ملفات تعريف الارتباط المتاحة بالنقر فوق &rdquo; أدوات&rdquo;، و &ldquo;خيارات إنترنت&rdquo;، و &ldquo;الخصوصية&rdquo;، ثم &ldquo;خيارات متقدمة&rdquo;.
                        </li>
                        <li>
                            في متصفح فايرفوكس Firefox (الإصدار 24)، يمكنك حظر جميع ملفات تعريف الارتباط عن طريق النقر على &ldquo;أدوات&rdquo;، و &ldquo;خيارات&rdquo;، و &ldquo;خصوصية&rdquo;، وتحديد &ldquo;استخدام الإعدادات المخصصة للتاريخ&rdquo; من القائمة المنسدلة، وإلغاء تحديد &ldquo;قبول ملفات تعريف الارتباط من المواقع&rdquo; .
                        </li>
                        <li>
                            في كروم Chrome (الإصدار 29)، يمكنك حظر جميع ملفات تعريف الارتباط عن طريق الوصول إلى قائمة &ldquo;تخصيص والتحكم&rdquo;، والنقر على &ldquo;الإعدادات&rdquo;، و &ldquo;إظهار الإعدادات المتقدمة&rdquo;، و &ldquo;إعدادات المحتوى&rdquo;، ثم تحديد &ldquo;حظر المواقع من إعداد أي بيانات&rdquo; تحت عنوان&rdquo; ملفات تعريف الارتباط &ldquo;.
                        </li>
                    </ul>
                </li>
                <li>
                    سيكون لحظر جميع ملفات تعريف الارتباط تأثير سلبي على سهولة استخدام العديد من المواقع. إذا قمت بحظر ملفات تعريف الارتباط، فلن تتمكن من استخدام جميع الميزات الموجودة على موقعنا.يمكنك حذف ملفات تعريف الارتباط المخزنة بالفعل على جهاز الكمبيوتر الخاص بك &ndash; على سبيل المثال:
                    <ul>
                        <li>
                            في إنترنت إكسبلورر Internet Explorer (الإصدار 10)، يجب عليك حذف ملفات تعريف الارتباط يدويًا (يمكنك العثور على تعليمات للقيام بذلك عبر  <a href="http://support.microsoft.com/kb/278835">الرابط التالي</a> ).
                        </li>
                        <li>
                            في فايرفوكس Firefox (الإصدار 24)، يمكنك حذف ملفات تعريف الارتباط بالنقر فوق &ldquo;أدوات&rdquo; و &ldquo;خيارات&rdquo; و &ldquo;خصوصية&rdquo;، ثم تحديد &ldquo;استخدام إعدادات مخصصة للتاريخ&rdquo;، والنقر فوق &ldquo;إظهار ملفات تعريف الارتباط&rdquo;، ثم النقر فوق &ldquo;إزالة&rdquo; جميع ملفات تعريف الارتباط &ldquo;؛
                        </li>
                        <li>
                            في كروم Chrome (الإصدار 29)، يمكنك حذف جميع ملفات تعريف الارتباط عن طريق الوصول إلى قائمة &ldquo;تخصيص والتحكم&rdquo;، والنقر على &ldquo;الإعدادات&rdquo;، و &ldquo;إظهار الإعدادات المتقدمة&rdquo;، و &ldquo;مسح بيانات التصفح&rdquo;، ثم تحديد &ldquo;حذف ملفات تعريف الارتباط و بيانات الموقع والمكونات الإضافية الأخرى &ldquo;قبل النقر فوق&rdquo; مسح بيانات التصفح &ldquo;.
                        </li>
                    </ul>
                </li>
            </ol>
            <p className="text-end">إدارة منصة مسار لتقنية المعلومات
                <br />
                <a href="/contact-us" className="btn-masar text-black btn-med-gray text-decoration-none p-2">تواصل معنا</a>

                <a href="./files/masarTerms.pdf" target="_blank" className="btn-masar btn-dark-orange text-decoration-none px-2 p-0 float-end mx-2">
                    تحميل <FontAwesomeIcon icon={faFileArrowDown} />
                </a>
            </p>
        </div>
    )

}