import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import UserDashboardList, { AdminDashboardList } from './UserDashboardList';

export default function UserSideBar() {

    const [isOpen, setIsOpen] = useState(false);
    function toggleMenu() { setIsOpen(!isOpen) };


    return (

        <div className="mt-5">
            <div className={`d-block d-sm-none slide-menu-container ${isOpen ? 'open' : ''}`}>
                <div className="text-end bg-light-orange text-white fs-6 rounded pe-2 pt-1" onClick={toggleMenu}>
                    {isOpen ? <FontAwesomeIcon icon={faCircleXmark} /> : <FontAwesomeIcon icon={faEllipsis} />}
                </div>
                <div className="px-5">
                    <ul
                        className="list-group pt-2">
                        <UserDashboardList />
                    </ul>
                </div>
            </div>
            <div className="d-none d-sm-flex flex-wrap justify-content-between">
                <ul
                    className="list-group pt-2 w-100">
                    <UserDashboardList />
                </ul>
            </div>
        </div>
    )
}

function AdminSideBar({ superAdminRole }) {

    const [isOpen, setIsOpen] = useState(false);
    function toggleMenu() { setIsOpen(!isOpen) };



    return (
        <div className="mt-5">
            <div className={`d-block d-sm-none slide-menu-container ${isOpen ? 'open' : ''}`}>
                <div className="text-end bg-light-orange text-white fs-6 rounded pe-2 pt-1" onClick={toggleMenu}>
                    {isOpen ? <FontAwesomeIcon icon={faCircleXmark} /> : <FontAwesomeIcon icon={faEllipsis} />}
                </div>
                <div className="px-5">
                    <ul
                        className="list-group pt-2">
                        <AdminDashboardList superAdminRole={superAdminRole} />
                    </ul>
                </div>
            </div>
            <div className="d-none d-sm-flex flex-wrap justify-content-between">
                <ul
                    className="list-group pt-2 w-100">
                    <AdminDashboardList superAdminRole={superAdminRole} />
                </ul>
            </div>
        </div>
    )
}

export { AdminSideBar }