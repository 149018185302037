import { AdminSideBar } from "../components/UserSideBar";
import React, { useState, useEffect } from 'react';
import { apiFetchAdmin } from '../helpers/apiFetch';
import moment from 'moment';
import 'moment/locale/ar'
import Popup from 'reactjs-popup';
import { BookDataModify } from "../components/ModiratorData";
import { allBooksCategories } from "../components/Data/CategoriesData"
import { Link } from "react-router-dom";
import Pagination from "../helpers/Pagination";
import { AddBooks } from "../components/AddBooks";


export function SettingBooks() {
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
    const [selectedBook, setSelectedBook] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedBookId, setSelectedBookId] = useState("");
    const [settingBooks, setSettingBooks] = useState("");
    const [settingAllBooks, setSettingAllBooks] = useState("");
    const [currentPage, setCurrentPage] = useState(1);


    const handleSelect = (e) => {
        const selectedValue = e.target.value;
        const category = allBooksCategories.find(cat => cat.en === selectedValue);
        setSelectedCategory(category);
        // Use category.en for internal logic
    };

    const booksCategories =
        [...new Set(settingAllBooks?.data?.result.map(item => item.category.type))];

    const totalPages = settingBooks?.data?.paginationInfo.totalPagesCount;

    useEffect(() => {
        async function fetchSettingBooks() {
            try {
                const booksAllData = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/library/books`);
                const booksData = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/library/books?${selectedCategory && `type=${selectedCategory}&`}page=${currentPage}&limit=8`);
                const profileData = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/users/my-profile`);

                setSettingAllBooks(booksAllData);
                setSettingBooks(booksData);
                setProfile(profileData);



            } catch (err) {
                setError("هناك مشكلة في جلب البيانات");
            }
        }

        fetchSettingBooks();
    }, [currentPage, selectedCategory]);


    const BookSample = ({ id, title, img, imgAlt, addDate, price, description, qty, available, author, pubYear, bookCategory, pages }) => (
        <div
            className="book-container col-sm-6 col-lg-3 my-1"
        >
            {!selectedBook &&
                <Popup
                    trigger={
                        <Link className="text-decoration-none text-black">
                            <img
                                crossOrigin="anonymous"
                                src={img ? img : "../images/BookSample-1.png"}
                                alt={imgAlt}
                                className="rounded-2" />
                            <div className="book-title pt-1">
                                <p>{title}</p>
                            </div>
                        </Link>
                    }
                    position="bottom left" modal>
                    <BookDataModify
                        title={title}
                        id={id}
                        img={img}
                        imgAlt={imgAlt}
                        addDate={addDate}
                        price={price}
                        description={description}
                        qty={qty}
                        available={available}
                        author={author}
                        pubYear={pubYear}
                        bookCategory={bookCategory}
                        pages={pages} />
                </Popup>
            }
        </div>

    );

    // console.log(settingBooks?.data?.result);

    const handleModiratorClick = (id) => {
        setSelectedBook(id);
    };

    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!settingBooks) {
        return <div className="loader"></div>;
    }

    return (

        <div className='container position-relative d-flex overflow-auto'>
            <div className='container mt-4 col-12'>
                <h6 className="text-end m-0 c-darkOrange"><span className="text-black">أهلا/</span> {profile?.data?.firstName}</h6>
                <div className="container my-3 rounded bg-light-gray position-relative p-3">
                    <select className="form-select form-select-sm w-auto mb-2" aria-label="books-category"
                        onChange={e => setSelectedCategory(e.target.value)}>
                        <option value="">الكل</option>
                        {allBooksCategories.map((category, index) =>
                            <option key={index} value={category.en}>{category.ar}</option>
                        )}
                    </select>

                    <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
                        {settingBooks?.data?.result.map((data, index) => (
                            <BookSample
                                key={data.id}
                                id={data.id}
                                title={data.title}
                                img={data.thumbnail}
                                imgAlt={`book_${data.id}`}
                                addDate={data.createdAt}
                                price={data.price}
                                description={data.description}
                                qty={data.quantity}
                                available={data.available}
                                author={data.author}
                                pubYear={data.publishedDate}
                                bookCategory={allBooksCategories.map(catg =>
                                    catg.en === data.category.type ? catg.ar : "")}
                                pages={data.numberOfPages} />
                        ))}
                        <div className="add-modirator d-flex justify-content-center align-items-center rounded-3 bg-white m-2 fs-1 c-darkGray"
                            data-bs-toggle="modal"
                            data-bs-target="#add-book">
                            +
                        </div>
                    </div>
                    {totalPages > 1 &&
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages} />
                    }

                </div>
            </div>
            <AddBooks />
        </div>
    )
}
