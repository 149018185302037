import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";


const userDashItems = [
    {
        itemNameAr: "معلوماتي",
        link: "/myAccount",
        activeLink: true,
    },
    {
        itemNameAr: "طلباتي",
        link: "/myRequests",
        activeLink: true,
    },
    {
        itemNameAr: "السلة",
        link: "/myBasket",
        activeLink: true,
    },
    {
        itemNameAr: "المفضلات",
        link: "/myFav",
        activeLink: false,
    },
    {
        itemNameAr: "مدفوعاتي",
        link: "/myPayments",
        activeLink: true,
    },
]

const AdminDashItems = [
    {
        id: 1,
        itemNameAr: "معلوماتي",
        link: "/admin",
        adminRole: "All"
    },
    {
        id: 2,
        itemNameAr: "الإشعارات",
        link: "/adminNotifi",
        adminRole: "All"
    },
    {
        id: 3,
        itemNameAr: "المشرفون",
        link: "/modirators",
        adminRole: "superAdmin"
    },
    {
        id: 4,
        itemNameAr: "الإعدادات",
        link: "/setting",
        adminRole: "All"
    }
]
const SettingDashItems = [
    {
        itemNameAr: "الكتب",
        itemNameEn: "Books",
        link: "/setting/books",
    },
    {
        itemNameAr: "الخدمات",
        itemNameEn: "Services",
        link: "/setting/services",
    },
    {
        itemNameAr: "المدفوعات",
        itemNameEn: "Payments",
        link: "/setting/payments",
    },
    {
        itemNameAr: "الأعمال",
        itemNameEn: "Profile",
        link: "/setting/profile",
    },
    {
        itemNameAr: "المستخدمين",
        itemNameEn: "Users",
        link: "/setting/users",
    },
]


export default function UserDashboardList() {

    const location = useLocation();
    const [selectedDashItem, setSelectedDashItem] = useState("");


    // Set selectedDashItem based on the current location
    useEffect(() => {
        // Extract the pathname from the location object
        const pathname = location.pathname;
        setSelectedDashItem(pathname);
    }, [location]);


    return (
        <>
            {
                userDashItems.map((item, index) => (
                    <li
                        key={index}
                        className={`${!item.activeLink && "d-none"} list-group-item list-group-item-action ps-2 ${selectedDashItem === item.link ? 'active' : ''}`} aria-current={selectedDashItem === item.link && "true"}>
                        <Link to={item.link} className={`d-block text-decoration-none text-black ${item.status} ${selectedDashItem === item.link ? 'text-white' : ''}`} >
                            {item.itemNameAr}
                        </Link>
                    </li>
                ))
            }
        </>

    )
}



function AdminDashboardList({ superAdminRole }) {

    const location = useLocation();
    const [selectedDashItem, setSelectedDashItem] = useState("");
    const [selectedSettingItem, setSelectedSettingItem] = useState("");
    const [settingOpen, setSettingOpen] = useState(false);


    // Set selectedDashItem based on the current location
    useEffect(() => {
        // Extract the pathname from the location object
        const pathname = location.pathname;
        setSelectedDashItem(pathname);
        setSelectedSettingItem(pathname);
        const isSettingPath = /^\/setting\/.+$/.test(location.pathname);
        isSettingPath && setSettingOpen(true)
        pathname === "/setting" && setSettingOpen(true)

    }, [location]);

    // /setting/books
    return (
        <>
            {
                AdminDashItems
                    .filter(item => item.id !== 4)
                    .map((item, index) => (
                        <li
                            key={index}
                            className={`admin list-group-item list-group-item-action ps-2 ${selectedDashItem === item.link ? 'active' : ''} `} aria-current={selectedDashItem === item.link && "true"}>
                            <Link to={item.link} className={`d-block text-decoration-none ${selectedDashItem === item.link ? 'c-darkBlue' : 'text-white'} ${item.id === 3 & superAdminRole !== item.adminRole && "d-none"}`} >
                                {item.itemNameAr}
                            </Link>
                        </li>

                    ))
            }
            {
                AdminDashItems
                    .filter(item => item.id === 4)
                    .map((item, index) => (
                        <li
                            key={index}
                            className={`admin list-group-item list-group-item-action ps-2 ${settingOpen ? 'active' : ''}`} aria-current={settingOpen && "true"}>
                            <Link to={`${item.link}/books`} className={`d-block text-decoration-none ${settingOpen ? 'c-darkBlue' : 'text-white'}`} >
                                {item.itemNameAr}
                            </Link>
                        </li>

                    ))
            }
            {settingOpen &&
                <ul className="list-group">
                    {SettingDashItems.map((item, index) => (
                        <li key={index} className={`admin list-group-item list-group-item-action ps-2 ${selectedSettingItem === item.link ? 'active' : ''}`} aria-current={selectedSettingItem === item.link && "true"}>
                            <Link to={item.link} className={`ps-3 d-block text-decoration-none  ${selectedSettingItem === item.link ? 'c-darkOrange' : 'text-light'}`}>
                                {item.itemNameAr}
                            </Link>
                        </li>
                    ))}
                </ul>
            }
        </>

    )
}

export { AdminDashboardList };