import { AdminSideBar } from "../components/UserSideBar";
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { apiFetchAdmin } from '../helpers/apiFetch';
import moment from 'moment';
import Cookies from 'js-cookie';
import MsgGetToken from '../helpers/MsgGetToken';
import 'react-toastify/dist/ReactToastify.css';
import { handleImageChange } from "../helpers/HandleFunctions/HandelImageChange"
import { editAdminData } from "../helpers/apiAxios"

export default function AdminAccount() {
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);


    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(false);
    const [image, setImage] = useState("/images/user0-img.jpg");


    useEffect(() => {
        if (!Cookies.get('adminToken')) {
            // if (!localStorage.getItem("adminToken")) {
            navigate("/adminLogin")
        }

    }, [navigate])

    const isSuperAdmin = profile?.data?.role === "superAdmin";
    const isoDate = profile?.data?.birthDate;
    const formattedBirthDate = moment(isoDate).format('YYYY-MM-DD');


    moment.locale('en');


    async function onSubmit(formData) {
        setLoading(true);

        try {
            const response = await editAdminData(formData);

            if (response.data.isSuccess) {
                setDataUpdate(true);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);

            } else {
                setErrorMessage("حدث خطأ ما، حاول مرة أخرى");
            }
        } catch (error) {
            console.error("Error during form submission: ", error);
            setErrorMessage("لا توجد صلاحية");
        } finally {
            setLoading(false);

        }
    }


    useEffect(() => {
        async function fetchProfile() {
            try {
                const data = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/users/my-profile`);
                setProfile(data);
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker.register('/firebase-messaging-sw.js')
                        .then((registration) => {
                            console.log('Service Worker registered with scope:', registration.scope);
                        }).catch((error) => {
                            console.error('Service Worker registration failed:', error);
                        });
                }
            } catch (err) {
                setError("هناك مشكلة تواصل مع الدعم");
            }
        }

        fetchProfile();
    }, []);

    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!profile) {
        return <div className="loader"></div>;
    }

    const adminName = profile?.data?.firstName;
    const adminImage = profile?.data?.image;


    return (
        <div className='container dashboard col-12 position-relative d-flex ps-0'>
            <div className='col-1 col-md-2 text-start bg-light-blue'>
                <AdminSideBar superAdminRole={profile?.data?.role} />
            </div>
            <div className='container mt-4 col-10'>
                <h6 className="text-end m-0 c-darkOrange"><span className="text-black">أهلا/</span> {adminName}</h6>
                <div className="container mt-3 rounded pb-5 bg-light-gray position-relative">
                    <form className='d-flex flex-column flex-md-row gap-2'
                        onSubmit={handleSubmit(onSubmit)}>

                        <div className="profile-image-container position-relative mt-4 my-md-auto mx-auto">
                            <img crossOrigin="anonymous" className="profile-image"
                                src={!adminImage ? image : adminImage}
                                alt={`${profile?.data?.role}_${profile?.data?.id}_Img`} />
                            <h6 className="profile-change-text border text-center">تغيير الصورة</h6>

                            <input
                                // disabled
                                className="opacity-0 profile-image-input"
                                type="file"
                                accept="image/jpeg"
                                onChange={(e) => handleImageChange(e, setValue, setImage)}
                            />
                        </div>

                        <div className="d-flex flex-column col-11 col-md-8 mx-auto my-4 gap-3">


                            <div className="input-group input-group-sm">
                                <span className="input-group-text">الاسم الأول</span>
                                <input
                                    className={`form-control ${errors.firstName && "border border-danger"}`} type="text"
                                    placeholder="الاسم الأول"
                                    defaultValue={profile?.data?.firstName}
                                    onChange={(e) => { setValue("firstName", e.target.value) }}
                                    {...register("firstName", { required: true, minLength: 3 })} />
                                {errors.firstName && <p className='errors-msg'>الاسم مطلوب أكبر من ٣ أحرف</p>}
                            </div>
                            <div className="input-group input-group-sm">
                                <span className="input-group-text">الاسم الأخير</span>
                                <input
                                    className={`form-control ${errors.lastName && "border border-danger"}`} type="text"
                                    placeholder="الاسم الأخير"
                                    defaultValue={profile?.data?.lastName}
                                    onChange={(e) => { setValue("lastName", e.target.value) }}
                                    {...register("lastName", { required: true, minLength: 3 })} />
                                {errors.lastName && <p className='errors-msg'>الاسم مطلوب أكبر من ٣ أحرف</p>}
                            </div>

                            <div className="input-group input-group-sm">
                                <span className="input-group-text" >البريد الالكتروني</span>
                                <input
                                    className='form-control'
                                    type="text"
                                    placeholder="البريد الالكتروني"
                                    defaultValue={profile?.data?.email}
                                    onChange={(e) => { setValue("email", e.target.value) }}
                                    {...register("email", { required: true })} />
                                {errors.email && <p className='errors-msg'>أدخل البريدالالكتروني</p>}
                            </div>

                            <div className="input-group input-group-sm">
                                <span className="input-group-text" >رقم الجوال</span>
                                <input
                                    className='form-control text-start'
                                    type="tel"
                                    placeholder="05xxxxxxxx"
                                    onChange={(e) => { setValue("mobilePhone", e.target.value) }}
                                    defaultValue={profile?.data?.mobilePhone}
                                    {...register("mobilePhone", { required: true })} />
                                {errors.mobilePhone && <p className='errors-msg'>أدخل رقم الجوال الصحيح</p>}
                            </div>

                            <div className="input-group input-group-sm">
                                <span className="input-group-text" >تاريخ الميلاد</span>
                                <input
                                    className='form-control text-start'
                                    type="date"
                                    placeholder="تاريخ الميلاد"
                                    defaultValue={formattedBirthDate}
                                    onChange={(e) => { setValue("birthDate", e.target.value) }}
                                // {...register("birthDate")} 
                                // onChange={(e) => { setValue("birthDate", moment(e.target.value).format()) }}

                                />
                                {errors.birthDate && <p className='errors-msg'>تاريخ الميلاد مطلوب</p>}
                            </div>

                            <div className="input-group input-group-sm">
                                <span className="input-group-text" >المسؤولية</span>
                                <input
                                    className='form-control text-start'
                                    type="text"
                                    placeholder="المسؤولية"
                                    value={profile?.data?.role}
                                    {...register("role")} disabled />
                            </div>

                        </div>
                        <button
                            className='btn-masar btn-dark-orange text-white col-2 me-3 mb-3 position-absolute bottom-0 end-0'
                            type="submit">
                            {loading &&
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"></span>
                            }
                            تعديل
                        </button>
                    </form>
                    {errorMessage &&
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {errorMessage}
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>}

                    {dataUpdate &&
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            تم تحديث ملفك بنجاح .. شكرا لك
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>}

                </div>
            </div>
            <MsgGetToken />
        </div>
    )
};