import { faXTwitter, faFacebookF, faYoutube, faSnapchat, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";

const socialData = [{
    icon: faXTwitter,
    link: "https://x.com/MnstMsar",
},
{
    icon: faFacebookF,
    link: "https://www.facebook.com/massarwebsite",
},
{
    icon: faYoutube,
    link: "https://www.youtube.com/channel/UCOSOawMHez1NUYVCXLxg2sQ",
},
{
    icon: faTiktok,
    link: "https://www.tiktok.com/@mnstmasar?lang=ar",
},
{
    icon: faInstagram,
    link: "https://www.instagram.com/masar.platform/",
},
];

const serviesData = [{
    title: "خدمات الكتب",
    link: "/services/servBooks"
},
{
    title: "المواقع والتطبيقات",
    link: "/services/servWeb"
},
{
    title: "الهوية البصرية",
    link: "/services/servIdentity"
},
{
    title: "مكتبة مسار",
    link: "/masarLibrary"
},
];

const othersData = [{
    title: "عن مسار",
    link: "/aboutUs",
    logInClass: ""
},
{
    title: "الشروط والأحكام",
    link: "/policy",
    logInClass: ""
},
{
    title: "سياسة الخصوصية",
    link: "/terms",
    logInClass: ""
},
{
    title: "مقدموا الخدمات",
    link: "/servicesProviders",
    logInClass: "disabled opacity-50"
},
];

export { socialData, serviesData, othersData };