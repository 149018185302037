import { SocialLinks, ServiesLinks, GeneralLinks } from "./FooterLinks"
import CopyRight from "./CopyRight"
import "./footer.css"

export default function Footer() {

  return (
    <footer className="bg-dark-blue mt-5 pt-4 pb-2 text-white">
      <div className="container d-flex align-items-center flex-wrap flex-sm-row flex-column-reverse justify-content-center gap-5">
        <SocialLinks />
        <ServiesLinks />
        <GeneralLinks />
        <img src="/images/masar-logo-footer.png" alt="Logo-Masar" className="logo-footer col-4 col-lg-3" />
      </div>
      <div className="mt-4 pt-2 border-top border-1">
        <CopyRight />
      </div>

    </footer>
  );
}

// footer for Admin and Users Dashbords
function FooterSmall() {
  return (
    <footer className="bg-dark-blue pt-1 pb-2 text-white">
      <div className="border-0 pt-1">
        <CopyRight />
      </div>

    </footer>
  );
}
export { FooterSmall }