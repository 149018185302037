import { validateWordCount } from "../../helpers/Validations/validateWords"

export default function ServiceLocationDescInput({ register, noLocation, errors }) {
    return (
        <>
            <input className={`${noLocation && "d-none"} form-control form-control-sm mb-2`} type="text" placeholder="المكان / العنوان" defaultValue={noLocation ? "أونلاين" : ""}
                {...register("location", { required: true })} />

            <textarea className="form-control form-control-sm mb-4" placeholder="وصف الطلب"
                {...register("description", {
                    required: "وصف الطلب مطلوب",
                    validate: validateWordCount
                })}
            ></textarea>

            {errors.description && <p className="text-danger">{errors.description.message}</p>}
        </>
    )
}

export function ServiceNumberOfForms({ register, noNumbers }) {
    return (
        <>
            <label className={`${noNumbers && " d-none"}`} htmlFor="#number-of-forms">عدد النماذج</label>
            <input className={`${noNumbers && " d-none"} form-select form-select-sm mb-3 w-auto`}
                type="number"
                max="500"
                min="1"
                id="number-of-forms"
                defaultValue="1"
                {...register("numberOfForms", { required: true })} />
        </>
    )
}

export function ServiceSelectLang({ register, noLang }) {
    return (

        <select className={`${noLang && " d-none"} form-select form-select-sm mb-3 w-auto`} {...register("language", { required: true })}>
            <option value={noLang ? "arabic" : ""}>اختر اللغة</option>
            <option value="arabic">العربية</option>
            <option value="english">الإنجليزية</option>
            <option value="arabic + english">العربية والإنجليزية</option>
        </select>
    )
}

export function FormInputs({ register, inputType, itemRegister, inputLabel, errors }) {
    return (
        <div className="input-group input-group-sm">
            <span className="input-group-text">{inputLabel}</span>
            <input className={`form-control ${errors.name && "border border-danger"}`}
                type={inputType}
                {...register({ itemRegister }, { required: true })} />
            {errors.name && <p className='errors-msg'>الاسم مطلوب أكبر من ١٠ أحرف</p>}
        </div>

    )
}