import Cookies from 'js-cookie';



export const apiFetch = async (url, options = {}) => {
    const token = Cookies.get('token');
    // const token = localStorage.getItem("token");

    const response = await fetch(url, {
        ...options,
        headers: {
            ...options.headers,
            "Authorization": `Bearer ${token}`,
            "Content-Type": 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    } if (response.status === 401) {

    }

    return response.json();
};

export const orderFetch = async (url, options = {}) => {
    // const token = localStorage.getItem("token");
    const token = Cookies.get('token');

    const response = await fetch(url, {
        ...options,
        headers: {
            ...options.headers,
            "accept": "*/*",
            "Authorization": `Bearer ${token}`,
            "Content-Type": 'multipart/form-data'
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
};


export const apiFetchAdmin = async (url, options = {}) => {
    const adminToken = Cookies.get('adminToken');

    // const adminToken = localStorage.getItem("adminToken");

    const response = await fetch(url, {
        ...options,
        headers: {
            ...options.headers,
            "Authorization": `Bearer ${adminToken}`,
            "Content-Type": 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
};
