import NavItems from "./NavItems"
import AdminAccount from "./AdminAccount";
import HeaderLogo from "./HeaderLogo";
import "./header.css"


export default function Header() {
  return (
    <header className="py-0 bg-light-gray">
      <div className="container-md d-flex">
        <HeaderLogo />
        <NavItems />
      </div>
    </header>

  );
}

// For admin dashboard pages
function AdminHeader() {
  return (
    <header className="py-0 bg-light-gray">
      <div className="container-md d-flex justify-content-between">
        <HeaderLogo />
        <AdminAccount />
      </div>
    </header>

  );
}

export { AdminHeader };