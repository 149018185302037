import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faUser, faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react"
import Cookies from 'js-cookie';
import handleLogout from '../../../helpers/HandleFunctions/HandleLogout';
import { apiFetchAdmin } from '../../../helpers/apiFetch';


export default function AdminAccount() {
    const [isLogIn, setIsLogIn] = useState(false);
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        // if (localStorage.getItem("adminToken")) {
        if (Cookies.get('adminToken')) {
            // if (profile) {
            setIsLogIn(true)
        } else {
            setIsLogIn(false)
        }
    }, [isLogIn]);


    return (
        <div className="user-icon d-flex flex-nowrap dropdown pt-2 pt-sm-0 align-items-center">
            {!isLogIn ?
                <FontAwesomeIcon icon={faUser} className="user-account text-white bg-dark-blue" />
                :
                <>
                    <FontAwesomeIcon icon={faBell}
                        className="user-bell text-white bg-light-blue"
                        type='button' id="adminNotifiBell" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" />
                    {/* <span className="position-absolute top-0 start-0 translate-center p-1 bg-danger border border-light rounded-circle"></span> */}


                    <ul className={`dropdown-menu dropdown-menu-end text-end bg-med-gray border-white`} aria-labelledby="adminNotifiBell">
                        <li>
                            <Link className="dropdown-item" to="/adminNotifi">الإشعارات</Link>
                        </li>
                    </ul>

                    <FontAwesomeIcon icon={faUser} className="user-account text-white bg-dark-orange" id="adminDropdown" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" />

                    <ul className="dropdown-menu dropdown-menu-end text-end bg-light-blue border-white" aria-labelledby="adminDropdown">
                        <li>
                            <Link className="dropdown-item"
                                onClick={() => handleLogout("adminToken", setIsLogIn)} to="/">
                                <FontAwesomeIcon className='me-3'
                                    icon={faArrowRightFromBracket} />
                                تسجيل الخروج</Link>
                        </li>
                        <li>
                            <Link className="dropdown-item" to="contact-us">المساعدة والدعم
                            </Link>
                        </li>

                    </ul>

                </>
            }


        </div >
    )
};

export { AdminAccount };


