import { AdminSideBar } from "../components/UserSideBar";
import React, { useState, useEffect } from 'react';
import { apiFetchAdmin } from '../helpers/apiFetch';
import moment from 'moment';
import 'moment/locale/ar'
import Pagination from "../helpers/Pagination";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { allServicesCategories, allStatusListItems } from "../components/Data/CategoriesData"

//// TO ADJUST PARAMETERS 

export default function AdminNotifi() {
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [orderStatus, setOrderStatus] = useState(null);
    const [orderNotifi, setOrderNotifi] = useState(null);
    const [notifiSeen, setNotifiSeen] = useState("");
    const navigate = useNavigate();
    const [allOrdersData, setAllOrdersData] = useState(null);

    useEffect(() => {
        if (!Cookies.get('adminToken')) {
            // if (!localStorage.getItem("adminToken")) {
            navigate("/adminLogin")
        }
    }, [navigate])

    moment.locale('ar_SA');
    const date = moment().format('Do MMMM YYYY, h:mm:ss a');
    const totalPages = orderNotifi?.data?.paginationInfo.totalPagesCount;


    // const selectedProfileCategory = allProfilesCategories.find(cat => cat.en === allProfileWorks?.data?.result[0].service.name)


    useEffect(() => {
        async function fetchNotifi() {
            try {
                const profileData = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/users/my-profile`);
                // const data = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/orders/admins?${orderStatus && `status=${orderStatus}&`}page=${currentPage}&limit=8`);
                const allOrders = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/orders/admins`);
                const data = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/notifications/my-notifications`);

                setProfile(profileData);
                setOrderNotifi(data);
                setAllOrdersData(allOrders);

            } catch (err) {
                setError("هناك مشكلة تواصل مع الدعم");
            }
        }

        fetchNotifi();
    }, []);


    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!orderNotifi) {
        return <div className="loader"></div>;
    }

    return (
        <div className='container dashboard col-12 position-relative d-flex ps-0'>
            <div className='col-1 col-md-2 text-start bg-light-blue'>
                <AdminSideBar superAdminRole={profile?.data?.role} />
            </div>
            <div className='container mt-4 col-11 col-md-10'>
                <h6 className="text-end m-0 c-darkOrange"><span className="text-black">أهلا/</span> {profile?.data?.firstName}</h6>
                <div className="container my-3 rounded bg-light-gray position-relative p-3">
                    {/* <select className="form-select form-select-sm w-50 mb-2" aria-label=""
                        onChange={e => setOrderStatus(e.target.value)} >
                        <option value="">الكل</option>
                        {allStatusListItems.map((item, index) =>
                            <option key={index} value={item.en}>{item.ar}</option>
                        )}
                    </select> */}
                    <table className="table table-hover">
                        <thead className="table-dark">
                            <tr>
                                {/* <th scope="col">م</th> */}
                                <th scope="col">الوقت والتاريخ</th>
                                <th scope="col">الإشعار</th>
                                {/* <th scope="col">العميل</th> */}
                                <th scope="col">الحالة</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderNotifi?.data?.result.map((notify) => (

                                <tr role="button" key={notify.notificationId} onClick={() => navigate("/setting/services")}>
                                    {/* <td className="col-1">{notify.notificationId}</td> */}
                                    <td className="col-4">{moment(notify.notification?.createdAt).format('Do MMMM YYYY, h:mm:ss a')}</td>
                                    <td className="col text-start" dir="ltr">
                                        {notify.notification?.body}
                                        {/* {selectedService.ar} */}
                                    </td>
                                    {/* <td className="col">
                                        { }</td> */}
                                    <td className={`col ${notify.notification?.createdAt === notify.notification?.updatedAt ? "text-success" : "text-warning"}`}>{notify.notification?.createdAt === notify.notification?.updatedAt ? "طلب جديد" : "تحديث"}</td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                    <p className="badge bg-warning">آخر ١٠ تنبيهات</p>

                </div>
                {/* {totalPages > 1 &&
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages} />
                } */}

            </div>
        </div>
    )
}