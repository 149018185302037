import SlideShow from "../components/SlideShow"
import ServiceComp from "../components/ServiceComp"
import LatestBooks from "../components/LatestBooks"
import { useState, useEffect } from "react";
import Cookies from 'js-cookie';


function Home() {
    const [login, setLogin] = useState(false);


    useEffect(() => {
        if (Cookies.get('token')) {
            setLogin(true)
        } else {
            setLogin(false)

        }
    }, []);


    return (
        <>
            <SlideShow />
            <ServiceComp />
            <LatestBooks />
        </>
    );
};


export default Home;
