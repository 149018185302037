import { Link, useNavigate } from 'react-router-dom';


export default function Pagination({ currentPage, setCurrentPage, totalPages }) {
    function goToNextPage() {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
    }
    function goToPreviousPage() {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
    }

    const goToPage = (page) => {
        setCurrentPage(page);
    };


    const getPageNumbers = () => {
        let startPage = Math.max(currentPage - 2, 1);
        let endPage = Math.min(currentPage + 2, totalPages);

        if (endPage - startPage < 4) {
            if (startPage === 1) {
                endPage = Math.min(startPage + 4, totalPages);
            } else if (endPage === totalPages) {
                startPage = Math.max(endPage - 4, 1);
            }
        }
        return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    };

    return (

        <nav className=" mt-2" aria-label="pages navigation">
            <ul className="pagination pagination-sm justify-content-center flex-row">
                <li className="page-item ">
                    <Link className={`page-link ${currentPage === 1 && "disabled"} c-darkBlue`}
                        onClick={goToPreviousPage}
                        tabIndex="-1"
                        aria-disabled={currentPage === 1}>
                        <span aria-hidden={currentPage === 1}>
                            &laquo;</span>
                    </Link>
                </li>

                {getPageNumbers().map((page) => (
                    <li key={page} className="page-item">

                        <Link
                            className={`page-link ${currentPage === page ? "c-darkOrange" : "c-darkBlue"}`}
                            onClick={() => goToPage(page)}
                            disabled={currentPage === page}
                        >
                            {page}
                        </Link>
                    </li>

                ))}



                <li className="page-item">
                    <Link className={`page-link ${currentPage === totalPages && "disabled"} c-darkBlue`}
                        onClick={goToNextPage}
                        tabIndex="-1"
                        aria-disabled={currentPage === totalPages}>
                        <span aria-hidden={currentPage === totalPages}>
                            &raquo;</span>
                    </Link>
                </li>


            </ul>
        </nav>
    )
}