
export const handleImageChange = (e, setValue, setImage, image) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
        setImage(reader.result);
    };
    setValue("image", file);
    if (file) {
        reader.readAsDataURL(file);
    }
};

export const handleBookImageChange = (e, setValue, setImage) => {
    const file = e.target.files[0];

    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            setImage(reader.result);
        };
        setValue("thumbnail", file);
        reader.readAsDataURL(file);
    }
};
