import { allBooksCategories } from "../components/Data/CategoriesData"
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import LibraryBook from "./libraryBook";
import RequestAddBook from "../components/RequestAddBook";
import Popup from 'reactjs-popup';
import { apiFetch } from '../helpers/apiFetch';
import Pagination from "../helpers/Pagination";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';



export default function MasarLibrary() {
    const [selectedCategory, setSelectedCategory] = useState('ShowAll');
    const [selectedBookId, setSelectedBookId] = useState("");
    const [libraryBooks, setLibraryBooks] = useState("");
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLogin, setIsLogin] = useState(false);
    const navigate = useNavigate();



    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
        setSelectedBookId(null);
    };



    const filteredBooksData = selectedCategory === 'ShowAll' ? libraryBooks?.data?.result : libraryBooks?.data?.result.filter(item => item.category.type === selectedCategory);


    const totalPages = libraryBooks?.data?.paginationInfo.totalPagesCount;

    const handleBookClick = (bookId) => {
        async function fetchLibrary() {
            try {
                const book = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/library/books/${bookId}`);

                setSelectedBookId(book?.data?.id);
                // console.log(book?.data?.id);

            } catch (err) {
                setError("هناك مشكلة في جلب الكتاب");
            }
        }

        fetchLibrary();

    };

    const userName = profile?.data?.fistName;

    useEffect(() => {
        if (!Cookies.get('token')) {
            navigate("/logIn")
        }
    }, [navigate]);


    useEffect(() => {
        async function fetchLibrary() {
            try {
                const booksData = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/library/books`);
                const booksData1 = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/library/books?page=${currentPage}&limit=20`);
                const profileData = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/users/my-profile`);

                setLibraryBooks(booksData);
                setProfile(profileData);


            } catch (err) {
                setError("هناك مشكلة في جلب البيانات");
            }
        }

        fetchLibrary();
    }, [currentPage]);


    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!libraryBooks) {
        return <div className="loader"></div>;
    }


    return (
        <div className="container mt-4">
            <div className="d-flex ">
                <h3 className="c-darkOrange col-7">مكتبة مسار</h3>
                <div className="col">
                    {totalPages > 1 &&
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages} />
                    }
                </div>
                {!selectedBookId &&
                    <Popup trigger={<button className="btn btn-masar btn-light-blue col-2">إضافة كتاب</button>} position="bottom left" modal>
                        <RequestAddBook />
                    </Popup>}
            </div>
            <div className="d-flex flex-wrap justify-content-between">
                <select className="form-select d-md-none" value={selectedCategory}
                    onChange={handleChange}>
                    <option value="ShowAll">كل الكتب</option>
                    {allBooksCategories.map((category, index) => (
                        <option key={index} value={category.en} >
                            {category.ar}
                        </option>
                    ))}
                </select>
                <ul className={`d-none d-md-block list-group col-6 ${selectedBookId ? "col-md-12 mb-3 text-center" : "col-md-3"} pt-2 h-100`}>
                    {selectedBookId ?
                        <li className={`list-group-item list-group-item-action ps-2 bg-light-gray`}
                            onClick={() => (setSelectedCategory('ShowAll'),
                                setSelectedBookId(null))}>
                            كل الكتب
                        </li>
                        :
                        <>
                            <li className={`list-group-item list-group-item-action ps-2 bg-light-gray`}
                                onClick={() => (setSelectedCategory('ShowAll'),
                                    setSelectedBookId(null))}>
                                كل الكتب
                            </li>
                            {allBooksCategories.map((category, index) => (
                                <li key={index} className={`list-group-item list-group-item-action ps-2 ${selectedCategory === category.en ? 'active' : ''}`} onClick={() => setSelectedCategory(category.en)}>
                                    {category.ar}
                                </li>
                            ))}
                        </>}
                </ul>
                {selectedBookId ?
                    <LibraryBook bookSetId={selectedBookId} setSelectedCategory={setSelectedCategory} /> :
                    <div className=" d-flex flex-wrap align-content-start col-12 col-md-9 pt-1 ">
                        {filteredBooksData.map((item) => (
                            <div
                                key={item.id}
                                className="book-container col-12 col-sm-4 col-lg-3 col-xxl-2 my-2"
                                onClick={() => handleBookClick(item.id)}
                            >
                                <Link className="text-decoration-none text-black">
                                    <img
                                        crossOrigin="anonymous"
                                        key={item.id}
                                        // to remove (!) when image of book allowed //
                                        src={item.thumbnail ? item.thumbnail : "../images/BookSample-1.png"}
                                        alt={`book-id-${item.id}`}
                                        className="rounded-2" />
                                    <div className="book-title pt-1">
                                        <p>{item.title}</p>
                                    </div>
                                </Link>
                            </div>
                        ))}

                    </div>
                }
            </div>

        </div>
    );
}
