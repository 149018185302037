export default function Search() {
    function doSearch(data) {
        const query = data.get("query");
        alert(`You searched for '${query}'`);
    }

    return (

        <form className="d-none d-lg-block mx-3 w-100" method="get" title="Search Form" action="https://cse.google.com/cse?cx=310c2ec76f4754cf0">
            <div>
                <input className="form-control-sm border-0 w-100" type="search" id="searchMasar" name="searchMasar" alt="بحث في الموقع" maxLength="256" placeholder="بحث"
                    aria-label="Search" />
                <input type="hidden" id="cx" name="cx" value="013626029654558379071:ze3tw4csia4" />
            </div>
        </form>


        // <div className="gcse-search d-none d-lg-block mx-3 w-100">

        //     <input
        //         name="query"
        //         className="form-control-sm border-0 w-100"
        //         type="search"
        //         placeholder="بحث"
        //         aria-label="Search" />

        //     {/* <form action={doSearch}
        //         className="" role="search">
        //         <input
        //             name="query"
        //             className="form-control-sm border-0 w-100"
        //             type="search"
        //             placeholder="بحث"
        //             aria-label="Search" />
        //     </form> */}
        // </div>
    )
};


function SearchInput({ searchQuery, setSearchQuery }) {

    return (
        <input
            name="queryRequests"
            className="form-control-sm border-0 col-8 mt-3"
            type="search"
            placeholder="بحث"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
        // onBlur={() => setSearching(false)}

        />

    )
}

export { SearchInput };