import { Outlet } from "react-router-dom";
import Header from "../components/common/Header/Header";
import { FooterSmall } from "../components/common/Footer/Footer";

export default function UserLayout() {
    return (
        <>
            <Header />
            <main style={{ minHeight: "calc(100vh - 145px)" }}>
                <Outlet />
            </main>
            <FooterSmall />
        </>
    );
}

