import { AdminSideBar } from "../components/UserSideBar";
import React, { useState, useEffect } from 'react';
import { apiFetchAdmin } from '../helpers/apiFetch';
import moment from 'moment';
import 'moment/locale/ar'
import { ProfileModify } from "../components/ModiratorData";
import { allProfilesCategories } from "../components/Data/CategoriesData"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../helpers/Pagination";
import { AddWorkProfile } from "../components/AddWorkProfile";



export function SettingProfile() {
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
    const [selectedBook, setSelectedBook] = useState("");
    const [settingProfileWorks, setSettingProfileWorks] = useState("");
    const [settingAllProfileWorks, setSettingAllProfileWorks] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState("");


    const ProfileSample = ({ id, imgSrc, imgAlt, imgCat, description }) => (
        <div className="admin-profile-img col-12 col-md-4 position-relative">
            <button className="btn-masar btn-med-gray text-secondary p-1 rounded position-absolute top-0 end-0 m-2"
                onClick={() => handleProfileWorkDelete(id)}><FontAwesomeIcon icon={faEraser}
                /></button>
            <p className="position-absolute bottom-0 start-50 mb-4 p-1 translate-middle-x bg-white w-75 text-center rounded">{imgCat}</p>
            <img crossOrigin="anonymous"
                src={imgSrc ? imgSrc : "/images/Profile-Sample0.jpg"}
                alt={imgAlt}
                className="rounded-4 w-100 p-1"
            // data-bs-toggle="modal" data-bs-target={`#profile-works-details-${id}`}
            />
            {/* <ProfileModify
                description={description}
                id={id}
                imgSrc={imgSrc}
                imgCat={imgCat} /> */}
        </div>
    );



    async function handleProfileWorkDelete(id) {

        try {
            await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/works/${id}`, { method: "DELETE" });
            const updatedProfileWorksData = {
                ...settingAllProfileWorks,
                data: {
                    ...settingAllProfileWorks.data,
                    result: settingAllProfileWorks?.data?.result.filter((itme) => itme.id !== id)
                }
            };
            setSettingAllProfileWorks(updatedProfileWorksData);

        } catch (err) {
            setError("هناك مشكلة في الحذف");
        }
    }

    const handleModiratorClick = (id) => {
        setSelectedBook(id);
    };


    const totalPages = settingProfileWorks?.data?.paginationInfo.totalPagesCount;

    useEffect(() => {
        async function fetchSettingProfileWorks() {
            try {
                const worksAllData = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/works`);
                const worksData = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/works?${selectedCategory ? `service=${selectedCategory}&` : ""}page=${currentPage}&limit=8`);
                const profileData = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/users/my-profile`);

                setSettingAllProfileWorks(worksAllData);
                setSettingProfileWorks(worksData);
                setProfile(profileData);



            } catch (err) {
                setError("هناك مشكلة في جلب البيانات");
            }
        }

        fetchSettingProfileWorks();
    }, [currentPage, selectedCategory]);



    const workCategory = allProfilesCategories.find(cat =>
        cat.en === settingProfileWorks?.data?.result[0].service?.name
    )

    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!settingProfileWorks) {
        return <div className="loader"></div>;
    }

    return (

        <div className='container position-relative d-flex overflow-auto'>
            <div className='container mt-4'>
                <h6 className="text-end m-0 c-darkOrange"><span className="text-black">أهلا/</span> {profile?.data?.firstName}</h6>
                <div className="container my-3 rounded bg-light-gray position-relative p-3">
                    <select className="form-select form-select-sm w-auto mb-2" aria-label="books-category"
                        onChange={e => setSelectedCategory(e.target.value)}>
                        <option value="">الكل</option>
                        {allProfilesCategories.map((category, index) =>
                            <option key={index} value={category.en}>{category.ar}</option>
                        )}
                    </select>

                    <div className="d-flex flex-wrap justify-content-center">

                        {settingProfileWorks?.data?.result.map((item) => (

                            <ProfileSample
                                key={item.id}
                                id={item.id}
                                description={item.description}
                                imgSrc={item.thumbinal}
                                imgAlt={`profileWorks_${item.id}`}
                                imgCat={allProfilesCategories.map(cat =>
                                    cat.en === item.service?.name ? cat.ar : "")
                                } />
                        ))}
                        <div className="add-book d-flex justify-content-center align-items-center rounded-3 bg-white m-2 fs-1 c-darkGray"
                            data-bs-toggle="modal"
                            data-bs-target="#add-work">
                            +
                        </div>
                    </div>
                    {totalPages > 1 &&
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages} />
                    }

                </div>
            </div>
            <AddWorkProfile />

        </div>
    )
}

