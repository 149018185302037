import { AdminSideBar } from "../components/UserSideBar";
import React, { useState, useEffect } from 'react';
import { apiFetchAdmin } from '../helpers/apiFetch';
import moment from 'moment';
// import 'moment/locale/ar'
import Popup from 'reactjs-popup';
import { BookDataModify } from "../components/ModiratorData";
import { Outlet } from "react-router-dom";
import { allServicesCategories, allStatusListItems, allSubServices } from "../components/Data/CategoriesData";
import { Link } from "react-router-dom";
import OrderDetials from "../components/OrderDetials";
import Pagination from "../helpers/Pagination"
import { useForm } from 'react-hook-form';
import { editOrderOffer } from '../helpers/apiAxios';
import { handlePDFClick } from "../helpers/HandleFunctions/HandlePDFOpen"

export function SettingServices() {
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState();
    const [superAdmin, setSuperAdmin] = useState(false)
    const [offerDataStatus, setOfferDataStatus] = useState()
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [serviceData, setServiceData] = useState(null);
    const [allServiceData, setAllServiceData] = useState(null);
    const [allUsersData, setAllUsersData] = useState(null);
    const [userDataTotalCount, setUserDataTotalCount] = useState("");

    const [loading, setLoading] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // const [orderId, setOrderId] = useState(null);

    // const userDataTotalCount = allUsersData?.data?.paginationInfo.totalCount;
    const totalPages = serviceData?.data?.paginationInfo.totalPagesCount;
    // console.log(allUsersData?.data?.result[0].mobilePhone);
    const customerPhoneNum = allUsersData?.data?.result.find(userData =>
        serviceData?.data?.result.user?.id === userData?.id)?.mobilePhone || "Not";
    // console.log(userDataTotalCount);

    const handleOrderClick = (id) => {
        setSelectedOrder(id);
    };



    const AdminService = ({ orderId, serviceName, serviceId, numberOfForms, language, orderDescription, orderCreated, orderUser, subServices, orderStatus, orderStatusEn, orderUserID, offerStatus, offerFile, offerNote, offerPrice, customerEmail, customerName, customerPhone, adminRole }) => (

        <tr
            className={`${orderStatusEn === "Rejected"
                && "table-danger"} ${orderStatusEn === "Pending"
                && "table-warning"}`}>


            <td className="col" > {`${moment(orderCreated).format('YY')}${new Intl.NumberFormat("en-IN", { minimumIntegerDigits: 2 }).format(
                serviceId,)}${new Intl.NumberFormat("en-IN", { minimumIntegerDigits: 3 }).format(orderId,)}`
            }</td>
            <td className="col">
                {moment(orderCreated).format('Do MMM YYYY')}</td>
            <td className="col">{serviceName}</td>
            <td className="col">{orderUser}
                {/* <br /><span className="text-decoration-underline c-lightBlue">{orderUserID}</span> */}
            </td>

            <td className="col">
                <button
                    className="btn-masar-sm btn-light-blue " data-bs-toggle="modal" data-bs-target={`#order-details-${orderId}`}>
                    عرض الطلب</button>
                <button className="btn-masar-sm btn-med-gray m-1" onClick={handlePDFClick} value={offerFile} disabled={!offerFile}>{!offerFile ? <span className="text-decoration-line-through">PDF</span> : "PDF"}</button>
                <OrderDetials
                    offerStatus={offerStatus}
                    orderId={orderId}
                    numberOfForms={numberOfForms}
                    language={language}
                    orderDescription={orderDescription}
                    subServices={subServices}
                    orderStatus={orderStatus}
                    offerNote={offerNote}
                    offerPrice={offerPrice}
                    serviceName={serviceName}
                    offerFile={offerFile}
                    customerEmail={customerEmail}
                    customerName={customerName}
                    customerPhone={customerPhone}
                    adminRole={adminRole}
                />
            </td>
            <td className="col">{offerStatus}</td>

        </tr>

    );

    // console.log(profile?.data?.role);


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredServiceData = allServiceData?.data?.result.filter(item =>
        item.user.firstName.toLowerCase().includes(searchQuery) ||
        item.user.lastName.toLowerCase().includes(searchQuery)
        // item.status.toLowerCase().includes(searchQuery) ||
        // item.id.toString().includes(searchQuery) ||
        // // item.createdAt.includes(searchQuery)
        // item.offerDetails.price.toString().includes(searchQuery)
        // item.offerDetails?.status.toLowerCase().includes(searchQuery) ||
    );

    useEffect(() => {
        async function fetchServices() {
            try {
                const profileData = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/users/my-profile`);
                const allData = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/orders/admins`);
                const data = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/orders/admins?${selectedStatus && `status=${selectedStatus}&`}page=${currentPage}&limit=5`);
                // const allUsers = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/users`);
                const allUsers = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/users?limit=${userDataTotalCount ? userDataTotalCount : 1}`);

                setAllUsersData(allUsers);
                setProfile(profileData);
                setServiceData(data);
                setAllServiceData(allData);
                setUserDataTotalCount(allUsers.data?.paginationInfo.totalCount)

            } catch (err) {
                setError("هناك مشكلة تواصل مع الدعم");
            }
        }

        fetchServices();
    }, [currentPage, selectedStatus, userDataTotalCount]);



    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!serviceData) {
        return <div className="loader"></div>;
    }
    moment.locale('en');


    console.log(serviceData);

    return (

        <div className='container position-relative d-flex col-11 col-md-10 m-0'>
            <div className='container mt-4'>
                <h6 className="text-end m-0 c-darkOrange"><span className="text-black">أهلا/</span> {profile?.data?.firstName}</h6>
                <div className="container my-3 rounded bg-light-gray position-relative p-3">
                    <div className="d-flex flex-wrap justify-content-center">
                        <input
                            name="queryServices"
                            className="form-control-sm border-0 w-auto"
                            type="search"
                            placeholder="بحث"
                            value={searchQuery}
                            onChange={handleSearchChange} />

                        <div className="container mt-1 position-relative pt-3 px-2 overflow-auto">
                            <div className="form-floating">
                                <select className="form-select form-select-sm w-75 mb-2"
                                    id="select-status"
                                    onChange={e => setSelectedStatus(e.target.value)}>
                                    <option value="">الكل</option>
                                    {allStatusListItems.map((oneStatus, index) =>
                                        <option key={index} value={oneStatus.en}>{oneStatus.ar}</option>
                                    )}
                                </select>
                                <label htmlFor="select-status text-start">حالة الطلب لدى العميل</label>
                            </div>

                            <table className="table table-hover">
                                <thead className="table-dark">
                                    <tr>
                                        <th scope="col">كود الطلب</th>
                                        <th scope="col">التاريخ</th>
                                        <th scope="col">الخدمة</th>
                                        <th scope="col">العميل</th>
                                        <th scope="col">الإجراءات</th>
                                        <th scope="col">موافقة المشرف</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(searchQuery ? filteredServiceData : serviceData)?.data?.result.map((order) => (
                                        <AdminService
                                            key={order.id}
                                            orderId={order.id}
                                            orderCreated={order.createdAt}
                                            serviceId={order.service.id}
                                            serviceName={allServicesCategories.map(cat => cat.en === order.service.name ? cat.ar : "")}
                                            orderUser={order.user.firstName + " " + order.user.lastName}
                                            orderUserID={order.user.id}
                                            orderStatusEn={order.status}
                                            orderStatus={allStatusListItems.map(oneStatus => oneStatus.en === order.status ? oneStatus.ar : "")}
                                            numberOfForms={order.numberOfForms}
                                            language={order.language}
                                            orderDescription={order.description}

                                            subServices={order.subServices &&
                                                order.subServices.map((e, index) => {
                                                    const matchedSubService = allSubServices.find(subServ => subServ.en === e);
                                                    return matchedSubService ? (index > 0 ? " - " : "") + matchedSubService.ar : "";
                                                }).filter(Boolean) // This removes any empty strings
                                            }

                                            offerStatus={allStatusListItems.map(oneStatus => oneStatus.en === order.offerDetails?.status ? oneStatus.ar : "")}
                                            offerFile={order.offerDetails?.file}
                                            offerPrice={order.offerDetails?.price}
                                            offerNote={order.offerDetails?.notes}
                                            customerEmail={order.user.email}
                                            customerName={order.user.firstName + " " + order.user.lastName}
                                            // customerPhone={customerPhoneNum}
                                            customerPhone={allUsersData?.data?.result.find(userData =>
                                                order?.user?.id === userData?.id)?.mobilePhone || ""}
                                            adminRole={profile?.data?.role}
                                        />

                                    ))}

                                </tbody>
                            </table>

                        </div>
                        {totalPages > 1 &&
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages} />
                        }


                    </div>
                </div>
            </div>
        </div>
    )
}
