import React from "react";
import { useState, useEffect } from "react";

const Timer = ({ OrderCreateAt, H }) => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const createTime = new Date(OrderCreateAt).getTime();
    const updatedTime = new Date(createTime + H * 60 * 60 * 1000).getTime();
    const deadline = new Date(updatedTime).getTime();

    const getTime = () => {
        const time = updatedTime - Date.now();

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };

    // console.log(deadline);

    useEffect(() => {
        const interval = setInterval(() => getTime(deadline), 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {days + hours + minutes + seconds > 0 ?
                <p className="c-darkOrange">
                    {days < 10 ? "0" + days : days}:
                    {hours < 10 ? "0" + hours : hours}:
                    {minutes < 10 ? "0" + minutes : minutes}:
                    {seconds < 10 ? "0" + seconds : seconds}
                </p>
                : <p className="text-warning m-0">
                    انتهى الوقت
                </p>}
        </>
    );
};
export default Timer;
