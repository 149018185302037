import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { socialData, serviesData, othersData } from '../../Data/LinksData';
import MasarAddress from "./MasarAdress"

// for social Links section in footer
function SocialLinks() {
    return (
        <div className="col-9 col-sm-5 col-md-4 col-lg-3">
            <Link to="/contact-us">
                <h6>تواصل معنا</h6>
            </Link>
            <MasarAddress />
            <div className="social-icons">
                {socialData.map((social, index) => (
                    <Link key={index} to={social.link} target='blank'>
                        <FontAwesomeIcon icon={social.icon} />
                    </Link>
                ))}
            </div>
        </div>
    )
};


// for Services Links section in footer
function ServiesLinks() {
    return (
        <ul className='d-none d-lg-block col-5 col-lg-3'>
            {serviesData.map((service, index) => (
                <li key={index} className='footer-links'>
                    <Link to={service.link} className='nav-link p-1' aria-current="page" >
                        {service.title}</Link>
                </li>
            ))}
        </ul>
    )
};

// for General Links section in footer
function GeneralLinks() {

    const [isLogIn, setIsLogIn] = useState(false);

    useEffect(() => {
        if (Cookies.get('token')) {
            setIsLogIn(true)
        } else {
            setIsLogIn(false)
        }
    }, [isLogIn]);

    return (
        <ul className='col-9 col-sm-5 col-md-4 col-lg-3'>
            {othersData.map((data, index) => (
                <li key={index} className='footer-links'>
                    <Link to={data.link} className={`nav-link p-1 ${isLogIn && data.logInClass}`} aria-current="page" >
                        {data.title}</Link>
                </li>
            ))}
        </ul>
    )
};

export { SocialLinks, ServiesLinks, GeneralLinks };