import ReCAPTCHA from "react-google-recaptcha";

export default function ReCaptchaForForms() {

    return (

        <ReCAPTCHA
            size="compact"
            sitekey={`${process.env.REACT_APP_reCAPTCHA_SITE_KEY}`}
            render="explicit"
        // verifyCallback={verifyCallback}
        // onloadCallback={callback}
        />


    )

}

export function ReCaptchaHidden() {

    return (

        <ReCAPTCHA
            size="invisible"
            sitekey={`${process.env.REACT_APP_reCAPTCHA_HIDDEN_SITE_KEY}`}
            render="explicit"
        // verifyCallback={verifyCallback}
        // onloadCallback={callback}
        />


    )

}