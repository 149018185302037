import PrivecyPolicy from "../components/PrivecyPolicy";
import TermsConditions from "../components/TermsConditions";


export function Terms() {
    return (
        <div className="container bg-light-gray my-4 rounded p-3 w-75 lh-lg"
            id="terms-accordion">

            <PrivecyPolicy />

            {/* <div>
                <h3>شروط الخدمة</h3>
                <p>الاطلاع على شروط الاستخدام</p>
                <a href="Terms.pdf" target="_blank"><img /></a>
            </div>
            <div>
                <h3>سياسة الخصوصية</h3>
                <p>الاطلاع على سياسة الخصوصية</p>
                <a href="Policy.pdf" target="_blank"><img /></a>
            </div> */}
        </div>
    );
};

export function Policy() {
    return (
        <div className="container bg-light-gray my-4 rounded p-3 w-75 lh-lg"
            id="terms-accordion">

            <TermsConditions />
        </div >
    );
};
