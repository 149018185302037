import { Outlet } from "react-router-dom";
import Header from "../components/common/Header/Header";
import Footer from "../components/common/Footer/Footer";

function Layout() {
    return (
        <>
            <Header />
            <main style={{ minHeight: "calc(100vh - 385px)" }}>
                <Outlet />
            </main>
            <Footer />
        </>
    );
}

export default Layout;