import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCartShopping, faBell } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react"
import Cookies from 'js-cookie';
import handleLogout from '../../../helpers/HandleFunctions/HandleLogout';
import { onMessage } from 'firebase/messaging';

// import { faBell } from "@fortawesome/free-solid-svg-icons"; 

export default function Account() {
    const [isLogIn, setIsLogIn] = useState(false);
    const [isNotifi, setIsNotifi] = useState(false);

    useEffect(() => {
        if (Cookies.get('token')) {
            setIsLogIn(true)
        }
    }, [isLogIn]);

    return (

        <div className="user-icon d-flex flex-nowrap justify-content-center dropdown pt-2 pt-sm-0">
            {/* *** This to hold Notification for user for now *** */}
            {/* <button className={`user-bell text-white bg-light-blue position-relative border-0 rounded rounded-circle ${!isLogIn && "d-none"}`}>
                <span className="position-absolute top-0 start-50 translate-middle p-1 bg-warning border border-light rounded-circle">
                </span>

                <FontAwesomeIcon icon={faBell} />
            </button> */}

            <FontAwesomeIcon icon={faCartShopping} className="user-cart text-white bg-light-blue" type='button' id="cartDropdown" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" />
            {isLogIn &&
                <ul className={`dropdown-menu dropdown-menu-end text-end bg-med-gray border-white`} aria-labelledby="cartDropdown">
                    <li>
                        <Link className="dropdown-item" to="/myBasket">إلى السلة</Link>
                    </li>
                </ul>
            }

            <FontAwesomeIcon type='button' icon={faUser} className={`user-account text-white ${isLogIn ? "bg-dark-blue" : "bg-light-orange"}  dropdown-toggle`} id="userDropdown" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" />

            <ul className={`dropdown-menu dropdown-menu-end text-end ${isLogIn ? " bg-med-gray" : "bg-light-orange"} border-white`} aria-labelledby="userDropdown">
                {isLogIn ?
                    <>
                        <li>
                            <Link className="dropdown-item"
                                onClick={() => handleLogout("token", setIsLogIn)}
                                to="/">تسجيل الخروج</Link>
                        </li>
                        <li>
                            <Link className="dropdown-item" to="myAccount">حسابي</Link>
                        </li>
                    </>
                    :
                    <>
                        <li>
                            <Link className="dropdown-item" to="/logIn">تسجيل الدخول</Link>
                        </li>
                        <li>
                            <Link className="dropdown-item" to="/register">إنشاء حساب</Link>
                        </li>
                    </>
                }
            </ul>


        </div>


    )
};



