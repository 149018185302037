import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";

export default function TermsConditions() {
    return (
        <div className="container">
            <h4 className="text-center c-darkBlue">اتفاقية الشروط والأحكام</h4>

            <h6 className="c-darkOrange"><strong>مقدمة</strong></h6>
            <p><strong> مرحبًا بكم في موقع "مسار التميز" </strong>(<a rel="noreferrer" target="_new" href="https://www.masar-t.com.sa">https://www.masar-t.com.sa</a>) التابعة لشركة مسار التميز لتقنية المعلومات<br />
                ٢٨٨٩ طريق الأمير سعد بن عبدالرحمن الأول، ٨٤٤٤</p>

            <h6 className="c-darkOrange"><strong>1&ndash; قبول الشروط</strong></h6>

            <p>باستخدامك لموقع "مسار التميز"، فإنك توافق على الالتزام بهذه الشروط والأحكام، وكذلك سياسة الخصوصية الخاصة بنا. إذا كنت لا توافق على أي جزء من هذه الشروط، يرجى التوقف عن استخدام الموقع.</p>

            <h6 className="c-darkOrange"><strong>2&ndash; وصف الخدمة</strong></h6>
            <p>"مسار التميز" يقدم الخدمات والحلول الإعلامية، النشر والتوزيع للكتب، وكذلك تصميم وبناء وإدارة المواقع وصفحات السوشيال ميديا.</p>
            <h6 className="c-darkOrange"><strong>3&ndash; إنشاء الحساب</strong></h6>

            <p>لاستخدام بعض خدماتنا، يجب عليك إنشاء حساب. يتطلب ذلك تقديم معلومات شخصية مثل الاسم، رقم الجوال، البريد الإلكتروني، العنوان، وتاريخ الميلاد. أنت مسؤول عن الحفاظ على سرية بيانات تسجيل الدخول الخاصة بك.</p>

            <h6 className="c-darkOrange"><strong>4&ndash; جمع المعلومات الشخصية</strong></h6>

            <p>نحن نقوم بجمع وتخزين المعلومات الشخصية التي تقدمها لنا، بما في ذلك الاسم، رقم الجوال، البريد الإلكتروني، العنوان، وتاريخ الميلاد. نستخدم هذه المعلومات لتحسين خدماتنا وللتواصل معك بشأن طلباتك واستفساراتك.</p>

            <h6 className="c-darkOrange"><strong>5&ndash; القيود والشروط الخاصة بالاستخدام</strong></h6>

            <p>لا يوجد لدينا شروط خاصة للاستخدام غير أن تكون مسؤولًا قانونيًا أمام الجهات المختصة أو من ينوب عنك.</p>

            <h6 className="c-darkOrange"><strong>6&ndash; الإعلانات</strong></h6>
            <p>حاليًا، لا يحتوي موقعنا على أي إعلانات.</p>

            <h6 className="c-darkOrange"><strong>7&ndash; سياسة استرداد الأموال والإلغاء</strong></h6>
            <p>يمكنك استرداد الأموال في حالة عدم الاتفاق وعدم وجود بديل آخر كإضافتهم لحسابك للعمليات المستقبلية.</p>

            <h6 className="c-darkOrange"><strong>8&ndash; تحديث المحتوى والخدمات</strong></h6>
            <p>نحتفظ بالحق في تحديث وتغيير المحتوى والخدمات على موقعنا بشكل دوري.</p>

            <h6 className="c-darkOrange"><strong>9&ndash; الالتزامات القانونية</strong></h6>
            <p>يلتزم المستخدم بالآداب العامة وخلو المعاملات من المحرمات شرعًا طبقا للدين الإسلامي، وكذلك خلوها من كل ما يتعارض مع القوانين المحلية.</p>

            <h6 className="c-darkOrange"><strong>10&ndash; الموافقة على الشروط والأحكام</strong></h6>
            <p>بموافقتك على هذه الشروط والأحكام، فإنك توافق أيضًا على سياسة الخصوصية الخاصة بنا.
                <br />
                يرجى مراجعة هذه المسودة وإبلاغي بأي تعديلات أو إضافات ترغب فيها.</p>

            <p className="text-end">إدارة منصة مسار لتقنية المعلومات
                <br />
                <a href="/contact-us" className="btn-masar text-black btn-med-gray text-decoration-none p-2">تواصل معنا</a>

                <a href="./files/masarPolicy.pdf" target="_blank" className="btn-masar btn-dark-orange text-decoration-none px-2 p-0 float-end mx-2">
                    تحميل <FontAwesomeIcon icon={faFileArrowDown} />
                </a>
            </p>

        </div>
    )

}