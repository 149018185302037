import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { allProfilesCategories } from "../components/Data/CategoriesData"
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ReCaptchaForForms, { ReCaptchaHidden } from '../helpers/ReCaptchaForForms';
import { apiFetch } from "../helpers/apiFetch"

export const ServicesProviders = () => {
    const form = useRef();
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();


    async function onSubmit(data) {
        setLoading(true);
        console.log("Form data: ", data); // Debug: Check form data
        // if (data.type && Array.isArray(data.type)) {
        //     data.type = data.type.join(', ');
        // }

        try {
            const response = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/mailer/services-providers`, {
                method: "POST",
                body: JSON.stringify(data)
            });

            // Redirect to another page after successful submission
            if (response.isSuccess) {
                setSuccess(true)
                setTimeout(() => {
                    navigate('/');
                }, 5000);

            } else {


                // console.log(response, response.message, response.status);
                setErrorMessage("حدث خطأ ما، حاول مرة أخرى", response.message);
            }
        } catch (error) {
            console.error("Error during form submission: ", error); // Debug: Check error
            setErrorMessage("حدث خطأ ما، تواصل مع الدعم");
        } finally {
            setLoading(false);
            // navigate('/');

        }
    }


    /// to send email by mailJS Keeb for backup

    // function onSubmit() {
    //     setLoading(true)

    //     emailjs
    //         .sendForm(`${process.env.REACT_APP_EMAILJS_SERVICE_ID}`, `${process.env.REACT_APP_EMAILJS_PROVIDERS_ID}`, form.current, {
    //             publicKey: `${process.env.REACT_APP_EMAILJS_PUBLIC_KEY}`,
    //         })
    //         .then(
    //             () => {
    //                 console.log('SUCCESS!');
    //                 setLoading(false)
    //                 setSuccess(true)
    //                 setTimeout(() => {
    //                     // window.location.reload();
    //                     navigate('/');
    //                 }, 5000);
    //             },
    //             (error) => {
    //                 console.log('FAILED...', error.text);
    //                 setErrorMessage('يوجد مشكلة تواصل معنا', error.text);
    //                 setLoading(false)
    //             },
    //         );
    // };


    return (

        <div>
            <div className='bg-light-gray my-4 py-3'>
                <h4 className='container c-darkOrange'>مقدموا الخدمات</h4>
                <p className='container'>سجل معنا كمقدم للخدمات.. فضلا قم بتعبئة النموذج وسنتواصل معك</p>
            </div>
            <div className='bg-dark-blue text-start'>

                <form id='service-provider-form' className='d-flex flex-column mx-auto my-4 gap-2 p-4 w-75 w-lg-50'
                    ref={form} onSubmit={handleSubmit(onSubmit)}
                    method="POST">

                    <div className="input-group input-group-sm">
                        <span className="input-group-text">الاسم</span>
                        <input className={`form-control ${errors.name && "border border-danger"}`}
                            type="text"
                            {...register("name", { required: true, minLength: 10 })} />
                        {errors.name && <p className='errors-msg'>الاسم مطلوب أكبر من ١٠ أحرف</p>}
                    </div>

                    <div className="input-group input-group-sm">
                        <span className="input-group-text">البريد الالكتروني</span>
                        <input className={`form-control ${errors.email && "border border-danger"}`}
                            type="email"
                            {...register("email", { required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ })} />
                        {errors.email && <p className='errors-msg'>أدخل البريدالالكتروني</p>}
                    </div>

                    <div className="input-group input-group-sm">
                        <span className="input-group-text" >رقم الجوال</span>
                        <input className='form-control text-start' type="tel" placeholder="05xxxxxxxx"
                            {...register("phone", { required: true, minLength: 10, pattern: /^((([0]{2}|[+])966|20)|0)(5\d{8})|(1\d{9})$/ })} />
                        {errors.phone && <p className='errors-msg'>أدخل رقم الجوال الصحيح</p>}
                    </div>

                    <div className="input-group input-group-sm">
                        <span className="input-group-text" >المجالات</span>
                        <select
                            className={`form-control form-control-sm`}
                            multiple
                            {...register("type", { required: true })}
                        >
                            <option defaultValue=""></option>
                            {allProfilesCategories.map((category, index) =>
                                <option key={index} value={category.ar}>{category.ar}</option>
                            )}
                        </select>
                        {errors.type && <p className='errors-msg'>أختر مجالا على الأقل</p>}
                    </div>


                    <div className="input-group input-group-sm">
                        <span className="input-group-text" >تفاصيل</span>
                        <textarea
                            className={`form-control form-control-sm`}
                            type="text"
                            rows={4}
                            {...register("description")}
                        />
                        {errors.description && <p className='errors-msg'>أختر مجالا على الأقل</p>}
                    </div>


                    <div className="align-self-end">
                        <ReCaptchaHidden />
                    </div>

                    <button className="btn btn-light-blue text-white align-self-end w-auto"
                        type='submit'>
                        {loading &&
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"></span>
                        }
                        أرسل
                    </button>

                    {errorMessage &&
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {errorMessage}
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>}
                    {success &&
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            تم ارسال رسالتكم بنجاح .. شكرا لك
                            <br />
                            سنتواصل معك قريبا
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>}
                </form>
            </div>
        </div>

    );
};

