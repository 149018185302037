export default function ServiceSubmitButton({ loading }) {
    return (

        <button
            className='float-end btn btn-masar btn-dark-orange w-25'
            type="submit">
            {loading &&
                <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>
            }
            إرسال
        </button>
    )
}