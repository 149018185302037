import ServSideBar from "../components/ServSideBar";
import UnderPrepare from "../components/underPrepare"
import { SevrHeadName } from "../components/SevrHeadName";
// import UnderPrepare from "../components/underPrepare"
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { userServiceReqest } from "../helpers/apiAxios"
import ServiceTermsAndUpload from "../components/ServiceTermsAndUpload";
import ServiceSubmitButton from "../components/orders/ServiceSubmitButton";
import SuccessErrorMsg from "../components/SuccessErrorMsg";
import ServiceLocationDescInput, { ServiceNumberOfForms, ServiceSelectLang } from "../components/orders/ServiceGeneralInputs";
import { useNavigate } from 'react-router-dom';
import ServiceSubServiceChoice from "../components/ServiceSubServiceChoice";
import { subServBanner } from "../components/SubServices";


export default function ServBanner() {
    const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
        defaultValues: {
            subServices: '',
        }
    });
    const [loading, setLoading] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();


    async function onSubmit(formData) {
        setLoading(true);
        // setErrorMessage(''); // Reset error message

        console.log(formData);

        try {
            const response = await userServiceReqest(formData);

            if (response.data.isSuccess) {
                setDataUpdate(true);
                setTimeout(() => {
                    navigate("/");
                }, 3000);

            } else {
                setErrorMessage("حدث خطأ ما، حاول مرة أخرى");
            }
        } catch (error) {
            console.error("Error during form submission: ", error);
            setErrorMessage(<p className="p-0 m-0">تأكد من  <Link to="/logIn">تسجيل الدخول</Link> أو تواصل معنا لحل المشكلة</p>);
        } finally {
            setLoading(false);
        }
    }


    return (
        <>
            <div className="container d-md-flex">
                <div>
                    <ServSideBar />
                </div>
                <div>
                    <SevrHeadName title="لافتات دعائية" />
                    <div className="container rounded bg-dark-blue p-4 w-100 mx-auto text-white">
                        <h5>تفاصيل الطلب</h5>

                        {/* ///// FORM INPUTS \\\\\\ */}
                        <form encType="multipart/form-data" method="post" className="mt-3" onSubmit={handleSubmit(onSubmit)}>


                            {/* // Not Shown inpusts  \\ */}
                            <input className="d-none" type="text"
                                {...register("service")} value="Advertising Signs" />

                            {/* // Shown inpusts  \\ */}

                            <ServiceSelectLang
                                register={register}
                                noLang={true}
                            />

                            <ServiceNumberOfForms
                                register={register} noNumbers={true}
                            />

                            <ServiceSubServiceChoice
                                subServNAME={subServBanner}
                                bookService={false}
                                getValues={getValues}
                                setValue={setValue} />

                            <ServiceLocationDescInput
                                register={register}
                                noLocation={false}
                                errors={errors}
                            />


                            <ServiceTermsAndUpload
                                errors={errors}
                                setValue={setValue}
                                register={register} />


                            <ServiceSubmitButton

                                loading={loading} />

                        </form>

                        <SuccessErrorMsg
                            errorMessage={errorMessage}
                            dataUpdate={dataUpdate}
                            successMessage={"تم إرسال طلبكم بنجاح..شكرا لك .. جاري الانتقال للصفحة الرئيسية"} />

                    </div>

                </div>
            </div>
        </>
    );
};


