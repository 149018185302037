
export const subServivcesBooks = [
    {
        arName: "تصميم غلاف",
        value: "Cover Design",
        icon: "../images/SubServ_CoverDesign.png",
        idName: "CoverDesign"
    },
    {
        arName: "التنسيق",
        value: "Formatting",
        icon: "../images/SubServ_Formatting.png",
        idName: "Formatting"
    },
    {
        arName: "التدقيق",
        value: "Proofreading",
        icon: "../images/SubServ_Proofreading.png",
        idName: "Proofreading"
    },
    {
        arName: "الصف",
        value: "Typesetting",
        icon: "../images/SubServ_Typesetting.png",
        idName: "Typesetting"
    },
    {
        arName: "النشر والتوزيع",
        value: "Publishing and Distribution",
        icon: "../images/SubServ_Publishing.png",
        idName: "Publishing"
    },
    {
        arName: "ترجمة",
        value: "Translation",
        icon: "../images/SubServ_Translation.png",
        idName: "Translation"
    },
    {
        arName: "تهيئة محتوى",
        value: "Content Preparation",
        icon: "../images/SubServ_Content.png",
        idName: "Content"
    },
    {
        arName: "تصميم داخلي",
        value: "Internal Design",
        icon: "../images/SubServ_InternalDesign.png",
        idName: "InternalDesign"
    },
    {
        arName: "الخط عربي",
        value: "Arabic Calligraphy",
        icon: "../images/SubServ_Calligraphy.png",
        idName: "Calligraphy"
    },
    {
        arName: "تهيئة مناهج",
        value: "Curriculum Preparation",
        icon: "../images/SubServ_CurriculumPre.png",
        idName: "CurriculumPre"
    },
    {
        arName: "تصميم مناهج",
        value: "Curriculum Design",
        icon: "../images/SubServ_CurriculumDes.png",
        idName: "CurriculumDes"
    },
    {
        arName: "الطباعة",
        value: "Printing",
        icon: "../images/SubServ_Printing.png",
        idName: "Printing"
    },
    {
        arName: "التغليف والشحن",
        value: "Shipping and Transport",
        icon: "../images/SubServ_Shipping.png",
        idName: "Shipping"
    },
];


export const subServWeb = [
    {
        arName: "تصميم موقع",
        value: "Website Design",
        idName: "WebsiteDesign"
    },
    {
        arName: "برمجة قواعد بيانات",
        value: "Database Programming",
        idName: "DatabaseProgramming"
    },
    {
        arName: "استضافة / دومين",
        value: "Hosting / Domain",
        idName: "HostingDomain"
    },
    {
        arName: "تصميم تطبيق",
        value: "Application Design",
        idName: "ApplicationDesign"
    }
];


export const subServSocial = [
    {
        arTitle: "باقة 1",
        value: "First Package",
        price: "3000",
        idName: "SocialPack1",
        packDetails: ["10 بوستات إعلانية:", "(3 فديوهات قصيرة)  + (7 تصميمات ثابته)", "يتم نشرها وفقا للمواعيد المحدده في الخطة التسويقية علي مواقع التواصل الاجتماعي المتفق عليها"],
    },
    {
        arTitle: "باقة 2",
        value: "Second Package",
        price: "5000",
        idName: "SocialPack2",
        packDetails: ["16 بوستات إعلانية", "(5 فديوهات قصيرة)  + (11 تصميم ثابت)", "يتم نشرها وفقا للمواعيد المحدده في الخطة التسويقية علي مواقع التواصل الاجتماعي المتفق عليها"],
    },
    {
        arTitle: "باقة 3",
        value: "Third Package",
        price: "7000",
        idName: "SocialPack3",
        packDetails: ["20 بوست إعلاني", "(7 فديوهات قصيرة)  + (13 تصميم ثابت)", "يتم نشرها وفقا للمواعيد المحدده في الخطة التسويقية علي مواقع التواصل الاجتماعي المتفق عليها.", "تصوير فيدو احترافي + 20 ريلز لتدعيم المادة المنشورة بجودة أفضل على مدار السنة."],
    },
    {
        arTitle: "باقة 4",
        value: "Fourth Package",
        price: "10000",
        idName: "SocialPack4",
        packDetails: ["20 بوست إعلاني", "(7 فديوهات قصيرة)  + (13 تصميم ثابت)", "يتم نشرها وفقا للمواعيد المحدده في الخطة التسويقية علي مواقع التواصل الاجتماعي المتفق عليها.", "تصوير فيدو احترافي + 20 ريلز لتدعيم المادة المنشورة بجودة أفضل على مدار السنة.", "التمويل شهرياً بمبلغ 2500 ريال."],
    },
];

export const subServSocialGeneral = ["تحليل شامل للعلامة التجارية.", "تحليل المنافسين في نفس المجال.", "اعداد خطة تسويقية مدروسة باحترافية.", "اعداد محتوي ابداعي.", "تنفيذ التصاميم الإبداعية.", "تنفيذ الفديوهات الاعلانية.", "النشر المجدول.", "إداره الإعلانات المدفوعة.", "التفاعل مع العملاء والرد على الإستفسارت.", "تقارير أداء الحسابات (كل ثلاث شهور)"];



export const subServAdv = [
    {
        arName: "تصميم",
        value: "Design",
        idName: "Design"
    },
    {
        arName: "التصميم + الطباعة",
        value: "Design + Printing",
        idName: "DesignAndPrinting"
    },
    {
        arName: "إيصال الاستلام",
        value: "Receiving Voucher",
        idName: "ReceivingVoucher"
    },
    {
        arName: "إيصال الصرف",
        value: "Disbursement Voucher",
        idName: "DisbursementVoucher"
    },
    {
        arName: "بطاقة العمل",
        value: "Business Card",
        idName: "BusinessCard"
    },
    {
        arName: "الأوراق الرسمية",
        value: "Official Papers",
        idName: "OfficialPapers"
    },
    {
        arName: "الفواتير",
        value: "Invoices",
        idName: "Invoices"
    }
];

export const subServPackage = [
    {
        arName: "تصميم",
        value: "Design",
        idName: "Design"
    },
    {
        arName: "التصميم + الطباعة",
        value: "Design + Printing",
        idName: "DesignAndPrinting"
    },
    {
        arName: "النمذجة",
        value: "Modeling",
        idName: "Modeling"
    }
];
export const subServBanner = [
    {
        arName: "تصميم",
        value: "Design",
        idName: "Design"
    },
    {
        arName: "التصميم + التنفيذ",
        value: "Design + Execution",
        idName: "DesignExecution"
    },
    {
        arName: "تنفيذ (طباعة وتركيب)",
        value: "Execution",
        idName: "Execution"
    },
    {
        arName: "التجديد",
        value: "Renovation",
        idName: "Renovation"
    }
];
export const subServPhoto = [
    {
        arName: "التصوير الفوتوغرافي",
        value: "Photography",
        idName: "Photography"
    },
    {
        arName: "فيديو قصير",
        value: "Short Video",
        idName: "ShortVideo"
    },
    {
        arName: "فيديو قصير (60 ثانية)",
        value: "Long Video",
        idName: "LongVideo"
    },
    {
        arName: "معالجة الصور وتحريرها",
        value: "Image Processing and Editing",
        idName: "ImageProcessingEditing"
    }
];
export const subServShow = [
    {
        arName: "تصميم الجناح 3D",
        value: "Exhibition Booth Design 3D",
        idName: "Booth3D"
    },
    {
        arName: "تنظيم أحداث",
        value: "Event Organization",
        idName: "EventOrganization"
    },
    {
        arName: "تجهيزات المعارض",
        value: "Exhibition Equipment Setup",
        idName: "ExhibitionSetup"
    }
];
