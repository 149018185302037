import { useState, useEffect } from 'react';

const useCountdownTimer = (initialTime) => {
    const calculateRemainingTime = () => {
        const orderDate = Date.parse("2024-05-20T18:49:53.924Z");
        const updatedDate = new Date(orderDate + 2 * 60 * 60 * 1000).getTime();
        const elapsedTime = Math.floor((updatedDate - orderDate) / 1000);
        const remainingTime = initialTime - elapsedTime;
        return remainingTime > 0 ? remainingTime : 0;
    };
    // , orderTimerCreated
    const [time, setTime] = useState(calculateRemainingTime);
    const [isRunning, setIsRunning] = useState(true);

    useEffect(() => {
        let timer;
        if (isRunning && time > 0) {
            timer = setInterval(() => {
                setTime((prevTime) => prevTime - 1);
            }, 1000);
        } else if (time <= 0) {
            setIsRunning(false);
        }
        return () => clearInterval(timer);
    }, [isRunning, time]);

    const reset = () => setTime(calculateRemainingTime());
    const stop = () => setIsRunning(false);
    const start = () => setIsRunning(true);

    return { time, reset, stop, start, isRunning };
};

export default useCountdownTimer;
