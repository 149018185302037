import { ServiceData } from "./ServiceData";
import { allServicesCategories } from "../components/Data/CategoriesData";

function SevrHeadName(props) {
    const service = ServiceData.filter(data => data.title === props.title);

    return (
        <div className="pt-4 text-center">
            <div className="d-flex gap-2 align-items-center c-darkBlue">
                <img
                    className="sm-ico"
                    src={`.${service[0].icon}`}
                    alt={service[0].imgAlt} />
                <h5>{service[0].title}</h5>
            </div>
            <div className="bg-light-gray py-4 my-3 rounded">
                <img className="w-75  align-items-center" src="../images/servSteps-img.png" alt="services steps" />
            </div>
        </div>
    )
}

export { SevrHeadName };