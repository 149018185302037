
export default function CopyRight() {
    const copyDate = new Date().getFullYear();
    return (
        <p className="text-center copy-rights border-0 mb-0">
            جميع الحقوق محفوظة لمسار التميز © {copyDate}
            <span className="float-end me-3 font-monospace">v1.0.0</span>
            <span className="float-start ms-3 font-monospace"><a href="/adminLogin">admin</a></span>
        </p>
    );
}