import { ServiceData } from "./ServiceData"



export default function LatestBooksAdv() {

    return (
        <div id="caroLatestBooks" className="carousel slide mt-1 container-md " data-bs-ride="carousel">
            <ol className="carousel-indicators list-none">
                {ServiceData.map((service, index) => (
                    <li key={index}
                        data-bs-target="#caroLatestBooks"
                        data-bs-slide-to={index}
                        className={index === 0 ? "active" : undefined}
                        aria-current={index === 0 && "true"}
                        aria-label={service.imgAlt}
                    ></li>
                ))}
            </ol>
            <div className="carousel-inner" role="listbox">
                {ServiceData.map((service, index) => (
                    <div key={index} className={index === 0 ? "carousel-item active" : "carousel-item "}>
                        <img
                            src={service.imgSrc}
                            className="w-100 d-block"
                            alt={service.imgAlt}
                        />
                    </div>
                ))}

            </div>
            <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#caroLatestBooks"
                data-bs-slide="prev"
            >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#caroLatestBooks"
                data-bs-slide="next"
            >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}